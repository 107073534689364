import React from "react";
import Header from "../components/layout/Header";

//Icones
import { MdOutlineEmail } from "react-icons/md";
import { MdCall } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaCheck, FaRegClock } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { RiUserFollowLine } from "react-icons/ri";
import { FaInstagram, FaFacebook, FaWhatsapp } from "react-icons/fa";

import Formulario from "../components/Formulario";
import styles from "./Contato.module.css";

//Translation
import { useTranslation } from "react-i18next";

//Components
import FloatButton from "../components/FloatButton";
import ButtonBlack from "../components/layout/ButtonBlack";

//Images
import header from "../assets/images_contacto/ContactoHeader3.webp";

function Contato() {
  //Translation
  const { t } = useTranslation();

  return (
    <div>
      <Header
        Titulo={t("Contacto01")}
        backgroundImage={header}
        backgroundPosition="70% 50% "
      />
      <FloatButton mobileScrollPoint={500} desktopScrollPoint={300} />
      <div className={styles.intro}>
        <div className={styles.introListTitle}>
          <h2> {t("Contacto02")} </h2>
          <p>
            {t("Contacto03")} <br /> {t("Contacto04")}
          </p>

          <ul className={styles.listIntro}>
            <li>
              {" "}
              <FaCheck />
              {t("Contacto05")}
            </li>
            <li>
              <FaCheck />
              {t("Contacto06")}
            </li>
            <li>
              {" "}
              <FaCheck />
              {t("Contacto07")}
            </li>
            <li>
              {" "}
              <FaCheck />
              {t("Contacto08")}
            </li>
          </ul>
        </div>
        <div className={styles.linhaVertical}></div>
        <div className={styles.containerButton}>
          <h2> {t("Contacto09")} </h2>
          <a href="https://wa.me/34651551473" target="_blank">
            <ButtonBlack
              className={styles.button}
              content={t("pedircitaLifting")}
            />
          </a>
        </div>
        <div className={styles.halfCircle}></div>
        <div className={styles.circle}></div>
      </div>

      <div className={styles.containerIcons}>
        <div className={styles.containerTitleIcons}>
          <h2 className={styles.titleIntro}> {t("Contacto10")} </h2>
          <div className={styles.contentIcons}>
            <div>
              <MdCall className={styles.icon} />
              <h3> {t("Contacto11")} </h3>
              <p className={styles.descricao}>{t("Contacto12")}</p>
              <a className={styles.link}>
                <a href="tel:+34651551473" target="_blank">
                  <p> {t("Contacto13")} </p>
                </a>
              </a>
            </div>

            <div>
              <IoLogoWhatsapp className={styles.icon} />
              <h3> {t("Contacto14")} </h3>
              <p className={styles.descricao}>{t("Contacto15")}</p>
              <a className={styles.link}>
                <a href="https://wa.me/34651551473" target="_blank">
                  <p> {t("Contacto16")} </p>
                </a>
              </a>
            </div>

            <div>
              <MdOutlineEmail className={styles.icon} />
              <h3> {t("Contacto17")} </h3>
              <p className={styles.descricao}>{t("Contacto18")}</p>
              <a
                href="mailto:info@dralexmoreiræsthetic.com"
                className={styles.link}
                target="_blank"
              >
                <p> {t("Contacto19")} </p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.containerFormulario}>
        <h2> {t("Contacto20")} </h2>
        <p>
          {t("Contacto21")} <br /> {t("Contacto22")}{" "}
        </p>
        <Formulario />
      </div>
      <div className={styles.containerIcons}>
        <div className={styles.containerTitleIcons}>
          <div className={styles.contentIcons}>
            <div>
              <FaRegClock className={styles.icon} />
              <h3> {t("Contacto24")} </h3>
              <p className={styles.descricao}> {t("Contacto25")} </p>
              <p style={{ color: "#67633e" }}>{t("Contacto26")}</p>
            </div>

            <div>
              <FaLocationDot className={styles.icon} />
              <h3> {t("Contacto27")} </h3>

              {/* <a
                className={styles.link}
                href="https://maps.app.goo.gl/6zmLS5VLMD1sws8c9?g_st=aw"
                target="_blank"
              >
                <FaLocationDot />

                <p className={styles.linkEnd}>
                  {t("Contacto28")} 
                </p>
              </a> */}

              <a
                className={styles.link}
                href="https://maps.app.goo.gl/FwiEJmCM7EAjybHn7"
                target="_blank"
              >
                <FaLocationDot />

                <p className={styles.linkEnd}>{t("Contacto29")}</p>
              </a>
             
            </div>
            <div>
              <RiUserFollowLine className={styles.icon} />
              <h3> {t("Contacto30")} </h3>
              <p className={styles.descricao}>{t("Contacto31")}</p>
              <div className={styles.medias}>
                <a
                  className={styles.iconMedia}
                  href="https://www.instagram.com/dr.alex_moreira/"
                  target="_blank"
                >
                  <FaInstagram />
                </a>
                <a
                  className={styles.iconMedia}
                  href="https://www.facebook.com/dralexmoreira"
                  target="_blank"
                >
                  <FaFacebook />
                </a>
                <a
                  className={styles.iconMedia}
                href="https://wa.me/34651551473"
                  target="_blank"
                >
                  <FaWhatsapp />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contato;
