import React, { useState, useEffect } from "react";
import "./PostForm.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { MdOutlineFileUpload } from "react-icons/md";
import { FaPlus, FaTrash, FaParagraph } from "react-icons/fa";
import { toast } from "react-toastify"; // Adicione esta linha para importar toast
import "react-toastify/dist/ReactToastify.css"; // Adicione esta linha para importar os estilos do react-toastify

function PostForm({ onPostCreated, editingPost, onFormReset }) {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [sections, setSections] = useState([]);
  const [photo, setPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(null);
  const [postType, setPostType] = useState("");
  const [conclusion, setConclusion] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (editingPost) {
      setTitle(editingPost.title);
      setContent(editingPost.content);
      setSections(editingPost.sections || []);
      setPostType(editingPost.postType);
      setPhoto(editingPost.photo);
      setPhotoPreview(
        editingPost.photo
          ? `https://api.dralexmoreiraesthetic.com/${editingPost.photo}`
          : null
      );
      setConclusion(editingPost.conclusion || "");
    } else {
      setTitle("");
      setContent("");
      setSections([]);
      setPostType("");
      setPhoto(null);
      setPhotoPreview(null);
      setConclusion("");
    }
  }, [editingPost]);

  const handleSectionChange = (index, key, value) => {
    const newSections = sections.map((section, i) => {
      if (i === index) {
        return { ...section, [key]: value };
      }
      return section;
    });
    setSections(newSections);
  };

  const handleSubsectionChange = (
    sectionIndex,
    subsectionIndex,
    key,
    value
  ) => {
    const newSections = sections.map((section, i) => {
      if (i === sectionIndex) {
        const newSubsections = (section.subsections || []).map(
          (subsection, j) => {
            if (j === subsectionIndex) {
              return { ...subsection, [key]: value };
            }
            return subsection;
          }
        );
        return { ...section, subsections: newSubsections };
      }
      return section;
    });
    setSections(newSections);
  };

  const handleParagraphChange = (sectionIndex, paragraphIndex, value) => {
    const newSections = sections.map((section, i) => {
      if (i === sectionIndex) {
        const newParagraphs = (section.paragraphs || []).map((paragraph, j) => {
          if (j === paragraphIndex) {
            return value;
          }
          return paragraph;
        });
        return { ...section, paragraphs: newParagraphs };
      }
      return section;
    });
    setSections(newSections);
  };

  const handleSubsectionParagraphChange = (
    sectionIndex,
    subsectionIndex,
    paragraphIndex,
    value
  ) => {
    const newSections = sections.map((section, i) => {
      if (i === sectionIndex) {
        const newSubsections = (section.subsections || []).map(
          (subsection, j) => {
            if (j === subsectionIndex) {
              const newParagraphs = (subsection.paragraphs || []).map(
                (paragraph, k) => {
                  if (k === paragraphIndex) {
                    return value;
                  }
                  return paragraph;
                }
              );
              return { ...subsection, paragraphs: newParagraphs };
            }
            return subsection;
          }
        );
        return { ...section, subsections: newSubsections };
      }
      return section;
    });
    setSections(newSections);
  };

  const addSection = () => {
    setSections([...sections, { title: "", paragraphs: [], subsections: [] }]);
  };

  const addParagraph = (sectionIndex) => {
    const newSections = sections.map((section, i) => {
      if (i === sectionIndex) {
        return {
          ...section,
          paragraphs: [...(section.paragraphs || []), ""],
        };
      }
      return section;
    });
    setSections(newSections);
  };

  const addSubsection = (sectionIndex) => {
    const newSections = sections.map((section, i) => {
      if (i === sectionIndex) {
        return {
          ...section,
          subsections: [
            ...(section.subsections || []),
            { title: "", paragraphs: [] },
          ],
        };
      }
      return section;
    });
    setSections(newSections);
  };

  const addSubsectionParagraph = (sectionIndex, subsectionIndex) => {
    const newSections = sections.map((section, i) => {
      if (i === sectionIndex) {
        const newSubsections = (section.subsections || []).map(
          (subsection, j) => {
            if (j === subsectionIndex) {
              return {
                ...subsection,
                paragraphs: [...(subsection.paragraphs || []), ""],
              };
            }
            return subsection;
          }
        );
        return { ...section, subsections: newSubsections };
      }
      return section;
    });
    setSections(newSections);
  };

  const removeSection = (index) => {
    setSections(sections.filter((_, i) => i !== index));
  };

  const removeParagraph = (sectionIndex, paragraphIndex) => {
    const newSections = sections.map((section, i) => {
      if (i === sectionIndex) {
        return {
          ...section,
          paragraphs: (section.paragraphs || []).filter(
            (_, j) => j !== paragraphIndex
          ),
        };
      }
      return section;
    });
    setSections(newSections);
  };

  const removeSubsection = (sectionIndex, subsectionIndex) => {
    const newSections = sections.map((section, i) => {
      if (i === sectionIndex) {
        return {
          ...section,
          subsections: (section.subsections || []).filter(
            (_, j) => j !== subsectionIndex
          ),
        };
      }
      return section;
    });
    setSections(newSections);
  };

  const removeSubsectionParagraph = (
    sectionIndex,
    subsectionIndex,
    paragraphIndex
  ) => {
    const newSections = sections.map((section, i) => {
      if (i === sectionIndex) {
        const newSubsections = (section.subsections || []).map(
          (subsection, j) => {
            if (j === subsectionIndex) {
              return {
                ...subsection,
                paragraphs: (subsection.paragraphs || []).filter(
                  (_, k) => k !== paragraphIndex
                ),
              };
            }
            return subsection;
          }
        );
        return { ...section, subsections: newSubsections };
      }
      return section;
    });
    setSections(newSections);
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPhoto(file);
      setPhotoPreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!content.trim()) {
      console.error("O conteúdo do post é obrigatório.");
      // Exibir uma mensagem de erro para o usuário
      return;
    }
    const formattedSections = sections.map((section) => ({
      ...section,
      content: (section.paragraphs || []).join("\n\n"),
      subsections: (section.subsections || []).map((subsection) => ({
        ...subsection,
        content: (subsection.paragraphs || []).join("\n\n"),
      })),
    }));

    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content); // Confirme se este valor não está vazio
    formData.append("sections", JSON.stringify(formattedSections));
    formData.append("postType", postType);
    formData.append("conclusion", conclusion);
    if (photo && typeof photo !== "string") {
      formData.append("photo", photo);
    }

    // Debugging logs
    for (let [key, value] of formData.entries()) {
      if (value instanceof File) {
        console.log(`${key}: ${value.name}`); // Mostra o nome do arquivo se for um File
      } else {
        console.log(`${key}: ${value}`);
      }
    }
    try {
      const response = await fetch(
        editingPost
          ? `http://api.dralexmoreiraesthetic.com/api/posts/${editingPost._id}`
          : "https://api.dralexmoreiraesthetic.com/api/posts",
        {
          method: editingPost ? "PUT" : "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Error: ${response.status} ${response.statusText} - ${errorText}`
        );
      }

      const newPost = await response.json();
      onPostCreated(newPost);
      onFormReset(); // Reset form after successful submission
      toast.success("¡Publicación creada con éxito!"); // Mostrar notificação de sucesso
    } catch (error) {
      console.error("Error creating post:", error);
      toast.error(
        "Error al crear/actualizar la publicación. Verifique la consola para más detalles."
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="post-form">
      <label htmlFor="title">Título principal</label>
      <input
        id="title"
        type="text"
        placeholder="Ingrese el título principal"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        required
      />
      <label htmlFor="content">Contenido principal</label>
      <ReactQuill
        id="content"
        value={content}
        onChange={setContent}
        placeholder="Ingrese el contenido principal"
        required
        className="descripcion"
      />
      <label htmlFor="postType">Tipo de publicación</label>
      <select
        id="postType"
        value={postType}
        onChange={(e) => setPostType(e.target.value)}
        required
      >
        <option value="">Seleccione el tipo de publicación</option>
        <option value="consejos">Consejos de Belleza</option>
        <option value="tendencias">Nuevas Tendencias</option>
        <option value="exito">Historias de Éxito</option>
        <option value="noticias">Eventos y Noticias</option>
      </select>
      {sections.map((section, index) => (
        <div key={index} className="section">
          <label htmlFor={`section-title-${index}`}>Título de la sección</label>
          <input
            id={`section-title-${index}`}
            type="text"
            placeholder="Ingrese el título de la sección"
            value={section.title}
            onChange={(e) =>
              handleSectionChange(index, "title", e.target.value)
            }
            required
          />
          {section.paragraphs &&
            section.paragraphs.map((paragraph, pIndex) => (
              <div key={pIndex} className="paragraph">
                <label htmlFor={`paragraph-${index}-${pIndex}`}>
                  Párrafo {pIndex + 1}
                </label>
                <ReactQuill
                  id={`paragraph-${index}-${pIndex}`}
                  value={paragraph}
                  onChange={(value) =>
                    handleParagraphChange(index, pIndex, value)
                  }
                  placeholder="Ingrese el párrafo"
                  required
                  className="descripcion"
                />
                <button
                  type="button"
                  onClick={() => removeParagraph(index, pIndex)}
                  className="buttonRemove"
                  title="Eliminar Párrafo"
                >
                  <FaTrash />
                </button>
              </div>
            ))}
          <div className="section-buttons">
            <button
              type="button"
              onClick={() => addParagraph(index)}
              className="buttonAddParagraph"
              title="Agregar Párrafo"
            >
              <FaParagraph /> Agregar Párrafo
            </button>
            <button
              type="button"
              onClick={() => addSubsection(index)}
              className="buttonAddSubsection"
              title="Agregar Subsección"
            >
              <FaPlus /> Agregar Subsección
            </button>
            <button
              type="button"
              onClick={() => removeSection(index)}
              className="buttonRemove"
              title="Eliminar Sección"
            >
              <FaTrash /> Eliminar Sección
            </button>
          </div>
          {section.subsections &&
            section.subsections.map((subsection, subIndex) => (
              <div key={subIndex} className="subsection">
                <label htmlFor={`subsection-title-${index}-${subIndex}`}>
                  Título de la subsección
                </label>
                <input
                  id={`subsection-title-${index}-${subIndex}`}
                  type="text"
                  placeholder="Ingrese el título de la subsección"
                  value={subsection.title}
                  onChange={(e) =>
                    handleSubsectionChange(
                      index,
                      subIndex,
                      "title",
                      e.target.value
                    )
                  }
                  required
                />
                {subsection.paragraphs &&
                  subsection.paragraphs.map((subParagraph, spIndex) => (
                    <div key={spIndex} className="paragraph">
                      <label
                        htmlFor={`subsection-paragraph-${index}-${subIndex}-${spIndex}`}
                      >
                        Párrafo {spIndex + 1}
                      </label>
                      <ReactQuill
                        id={`subsection-paragraph-${index}-${subIndex}-${spIndex}`}
                        value={subParagraph}
                        onChange={(value) =>
                          handleSubsectionParagraphChange(
                            index,
                            subIndex,
                            spIndex,
                            value
                          )
                        }
                        placeholder="Ingrese el párrafo"
                        required
                        className="descripcion"
                      />
                      <button
                        type="button"
                        onClick={() =>
                          removeSubsectionParagraph(index, subIndex, spIndex)
                        }
                        className="buttonRemove"
                        title="Eliminar Párrafo"
                      >
                        <FaTrash className="iconButton" />
                      </button>
                    </div>
                  ))}
                <div className="subsection-buttons">
                  <button
                    type="button"
                    onClick={() => addSubsectionParagraph(index, subIndex)}
                    className="buttonAddParagraph"
                    title="Agregar Párrafo"
                  >
                    <FaParagraph className="iconButton" /> Agregar Párrafo
                  </button>
                  <button
                    type="button"
                    onClick={() => removeSubsection(index, subIndex)}
                    className="buttonRemoveSubsection"
                    title="Eliminar Subsección"
                  >
                    <FaTrash className="iconButton" /> Eliminar Subsección
                  </button>
                </div>
              </div>
            ))}
        </div>
      ))}
      <button type="button" onClick={addSection} className="buttonAdd">
        <FaPlus /> Agregar Sección
      </button>
      <div className="upload-files-container">
        <div className="drag-file-area">
          <div className="icon-upload">
            <MdOutlineFileUpload />
          </div>
          <label className="label">
            <span className="browse-files">
              <input
                type="file"
                className="default-file-input"
                onChange={handlePhotoChange}
              />
              <span className="browse-files-text">Buscar archivos</span>
              <span> desde el dispositivo</span>
            </span>
          </label>
        </div>
        {photoPreview && (
          <div className="photo-preview">
            <img
              src={photoPreview}
              alt="Vista previa"
              className="photo-preview-img"
            />
          </div>
        )}
      </div>
      <label htmlFor="conclusion">Texto de conclusión</label>
      <ReactQuill
        id="conclusion"
        value={conclusion}
        onChange={setConclusion}
        placeholder="Ingrese el texto de conclusión"
        required
        className="descripcion"
      />
      <button type="submit" className="buttonCrear">
        {editingPost ? "Actualizar Publicación" : "Crear Publicación"}
      </button>
    </form>
  );
}

export default PostForm;
