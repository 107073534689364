import Header from "../components/layout/Header";
import imgAgenda from "../assets/images_home/HomeAgendar2.webp";
import { useState, useEffect } from "react";

//Imagem icones
import iconDuracao from "../assets/aplicacion.svg";
import Icon from "../components/layout/Icones";
import iconRecuperacion from "../assets/icons/tiemporecuperacion.png";
import iconDuracion from "../assets/icons/duracion.svg";
import iconAnestesia from "../assets/icons/anestesia.png";

import { IoMdArrowForward } from "react-icons/io";
import { useInView } from "react-intersection-observer";

import header from "../assets/images_lifting/LiftingHeaderCort1.webp";
import imgInicio from "../assets/images_lifting/liftingTextCort(1).webp";
import imgLiftingCard from "../assets/images_lifting/LiftingCardCort.webp";

import styles from "./lifting.module.css";
import { AiOutlineMedicineBox } from "react-icons/ai";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import Perguntas from "../components/Perguntas";
import { Button } from "react-bootstrap";
import CardDuvidas from "../components/layout/cardsDuvidas";
import FloatButton from "../components/FloatButton";

//Translation
import { useTranslation } from "react-i18next";

function Lifting() {
  const { ref, inView } = useInView({
    triggerOnce: true, // A animação será acionada apenas uma vez
    threshold: 0.5, // A animação será acionada quando 50% do elemento estiver visível
  });

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (inView) {
      setAnimate(true);
    }
  }, [inView]);

  //Translation
  const { t } = useTranslation();

  return (
    <div>
      <Header
        Titulo={t("headerLifting")}
        backgroundImage={header}
        id="headerLifting"
      />
      <FloatButton mobileScrollPoint={500} desktopScrollPoint={300} />
      <div className={styles.containerIcons}>
        <div className={styles.circleBig}></div>
        <div className={styles.iconsTexto}>
          <Icon
            src={iconDuracao}
            title={t("iconLifting01")}
            subtitle={t("descricaoIcon01")}
          />
          <Icon
            src={iconAnestesia}
            title={t("iconLifting02")}
            subtitle={t("descricaoIcon02")}
          />
        </div>
        <div className={styles.iconsTexto}>
          <Icon
            src={iconRecuperacion}
            title={t("iconLifting03")}
            subtitle={t("descricaoIcon03")}
          />
          <Icon
            src={iconDuracion}
            title={t("iconLifting04")}
            subtitle={t("descricaoIcon04")}
          />
        </div>
        <div className={styles.linhaVertical}></div>
        <div className={styles.icon}>
          <a href="https://wa.me/34651551473" target="_blank">
            <Button className={styles.button}> {t("pedircitaLifting")} </Button>
          </a>
        </div>

        <div className={styles.halfCircle}></div>
        <div className={styles.circle}></div>
      </div>

      <div className={styles.containerIntro}>
        <div>
          <img
            src={imgInicio}
            className={`${styles.imgIntro} ${
              animate ? `${styles.fadeIn} ${styles.slideRight}` : ""
            }`}
            ref={ref}
            alt="background header"
          />
        </div>
        <div className={styles.containerTexto}>
          <h2
            className={`${styles.titleIntro} ${
              animate ? `${styles.fadeIn} ${styles.slideLeft}` : ""
            }`}
            ref={ref}
          >
            {t("titleIntro")}

            <p
              className={`${styles.subtitleIntro} ${
                animate ? `${styles.fadeIn} ${styles.slideLeft}` : ""
              }`}
              ref={ref}
            >
              {t("subTitleIntro")}
            </p>
          </h2>
          <p>{t("liftingText01")}</p>
        </div>
      </div>
      <ParallaxProvider>
        <Parallax speed={30} translateY={["0px", "320px"]}>
          <h1 className={styles.titlePrincipalContainer}>
            {t("titleParallaxLifting")}
          </h1>
          <div className={styles.circleTitle}></div>
        </Parallax>
      </ParallaxProvider>

      <div className={styles.containerPrincipal}>
        <div className={styles.contentPrincipal}>
          <div className={styles.principal}>
            <CardDuvidas
              title={t("cardTitleLifting01")}
              content={[t("cardLifting01")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />
            <CardDuvidas
              title={t("cardTitleLifting02")}
              content={[t("cardLifting02")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />

            <CardDuvidas
              title={t("cardTitleLifting03")}
              content={[t("cardLifting03")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />
          </div>
        </div>
      </div>
      <div className={styles.containerProcesso}>
        <div>
          <div
            className={`${styles.cardShort} ${styles.slideRight}`}
            id={styles.cardTratamiento}
          >
            <div className={styles.contentCard}>
              <p className={styles.titlePrincipal} isLarge={true}>
                {t("beneficioLifting")}
              </p>
              <p className={`${styles.conteudo} ${styles.conteudoAdd}`}>
                <span key="1" className={styles.contentBeneficio}>
                  <strong className={styles.contentBeneficio}> </strong> -
                  {t("beneficioLifting1")}
                </span>
                <br />
                <span key="2" className={styles.contentBeneficio}>
                  <strong className={styles.contentBeneficio}></strong> -
                  {t("beneficioLifting2")}
                </span>
                <br />
                <span key="3" className={styles.contentBeneficio}>
                  <strong className={styles.contentBeneficio}></strong> - Mejora
                  la textura y luminosidad de la piel.
                </span>
                <br />
                <span key="4" className={styles.contentBeneficio}>
                  <strong className={styles.contentBeneficio}></strong> -
                  {t("beneficioLifting2")}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.descricao}>
          <h4 className={styles.slideLeft}> {t("titleProceso")} </h4>
          <div>
            <ul className={styles.listTratamiento}>
              <li>
                <div className={styles.containerList}>
                  {/* <div className={styles.linhaVerticalIcons}></div> */}
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>

                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}>{t("listConsult")}</p>
                    <p>{t("textConsult")}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className={styles.containerList}>
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>
                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}>
                      {t("listAplicacion")}
                    </p>
                    <p>{t("textAplicacion")}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className={styles.containerList}>
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>
                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}>{t("titleCuidad")}</p>
                    <p>{t("textCuidad")}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.containerPlus}>
        <h4>{t("titleOpcionesH4")}</h4>
        <div className={styles.contentPlus}>
          <div className={styles.circlePlus}>
            <a href="/ArrugasdeExpresion">{t("linkArrugas")}</a>
          </div>
          <div className={styles.circlePlus}>
            <a href="/SurcoNasogeniano">{t("linkSurco")}</a>
          </div>
          <div className={styles.circlePlus}>
            <a href="/Ojeras">{t("linkOjeras")}</a>
          </div>
        </div>
        <div>
          <Button className={styles.button} id={styles.buttonPlus}>
            <a className={styles.linkServicios} href="/Servicios">
              {t("linkTratamientos")}
            </a>
          </Button>
        </div>
      </div>
      <div className={styles.containerAgenda}>
        <div className={styles.cardAgenda}>
          <div className={styles.contentAgenda}>
            <h1 className={styles.titleAgenda}>{t("agendaLifting")} </h1>

            <h1 className={styles.subtitleAgenda}>
              {" "}
              {t("tuCitaAhoraLifting")}{" "}
            </h1>
          </div>
          <a href="https://wa.me/669762160">
            <Button className={styles.buttonAgenda}>
              {" "}
              {t("pedircitaLifting")}{" "}
            </Button>
          </a>
        </div>
        <img src={imgAgenda} className={styles.imgAgenda} />
      </div>
      <div className={styles.containerPerguntas}>
        <div className={styles.perguntas}>
          <h2> {t("preguntasLifting")} </h2>
          <Perguntas
            pergunta={t("preguntasLifting1")}
            resposta={t("respuestaLifting1")}
            eventKey="0"
            onClick="0"
          />
          <Perguntas
            pergunta={t("preguntasLifting2")}
            resposta={t("respuestaLifting2")}
            eventKey="2"
          />
          <Perguntas
            pergunta={t("preguntasLifting3")}
            resposta={t("respuestaLifting3")}
            eventKey="3"
          />
        </div>
      </div>
    </div>
  );
}

export default Lifting;
