import Accordion from "react-bootstrap/Accordion";
import { useState } from "react";
import styles from "./Perguntas.module.css";
function Perguntas({ pergunta, resposta, eventKey, handleToggleValue }) {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = (eventKey) => {
    setExpanded(eventKey !== expanded); // Toggle state based on clicked item
  };

  return (
    <div>
      <Accordion className={styles.containerComentarios}>
        <Accordion.Item
          eventKey={eventKey}
          onClick={() => handleToggle({ handleToggleValue })}
          expanded={expanded === eventKey}
          className={styles.accordionHeader}
        >
          <Accordion.Header className={styles.perguntas}>
            {pergunta}
          </Accordion.Header>
          <Accordion.Body className={styles.respostas}>
            {resposta}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <style jsx>{`
        .sectionComentarios {
          height: ${expanded ? "auto" : "50vw"};
        }
      `}</style>
    </div>
  );
}

export default Perguntas;
