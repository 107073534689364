import { Button } from "react-bootstrap";
import styles from "./ButtonWhite.css";

function ButtonWhite({ content, type }) {
  return (
    <Button type={type} className="buttonWhite">
      {content}
    </Button>
  );
}

export default ButtonWhite;
