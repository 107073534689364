import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import styles from "./PopUp.module.css";
import ButtonGreen from "../layout/ButtonGreen";
import { useTranslation } from "react-i18next";
import image from "../../assets/Components/PopUp/imgVector.png";

function PopUp({ isVisible, onHide }) {
  const [show, setShow] = useState(false);
  const [triggered, setTriggered] = useState(false); // Estado para controlar a exibição única
  const { t } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 1000 && !triggered) {
        setShow(true); // Mostrar o modal quando o scroll atingir 1000px
        setTriggered(true); // Garantir que o modal só seja mostrado uma vez
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Limpar o evento de scroll quando o componente for desmontado
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [triggered]); // Dependência de "triggered" para garantir que o efeito ocorra apenas uma vez

  return (
    <Modal
      show={show}
      onHide={onHide}
      onExited={() => console.log("Modal totalmente fechado")}
      className={`${styles.modal} ${show ? styles.fadeIn : ""}`}
    >
      <Modal.Header className={styles.modalHeader}>
        <button
          type="button"
          className={`btn-close ${styles.customCloseButton}`}
          aria-label="Close"
          onClick={() => setShow(false)}
          onClickCapture={onHide}
        ></button>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <div className={styles.background}>
          <div className={styles.container}>
            <div className={styles.content}>
              <div className={styles.titlesContent}>
                <h1 className={styles.title}>{t("popUpTitle")}</h1>
                <h2 className={styles.subtitle}>{t("popUpSubtitle")}</h2>
              </div>
              <div className={styles.list}>
                <div className={styles.listItem}>
                  <p>
                  {t("popUpConteudo1")}
                  </p>
                </div>
                <div className={styles.listItem}>
                  <p>{t("popUpConteudo2")}</p>
                </div>
              </div>
              <div className={styles.contentButton}>
                <a
                  href="\laserlipolisis"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ButtonGreen content={t("popUpBotao")} />
                </a>
              </div>
            </div>
            <div className={styles.imgVector}>
              <img className={styles.image} src={image} alt="Decorative" />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PopUp;
