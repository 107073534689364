import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./Blog.module.css";
import Header from "../../components/layout/Header";
import PostFilter from "./Components/PostFilter";
import PostContent from "./Components/PostContent";
import header from "../../assets/Blog/header_blog.jpg";
import useDataBlog from "../../Data/posts"; // Atualize o caminho conforme necessário
import { useTranslation } from "react-i18next";
import FloatButton from "../../components/FloatButton";

function Blog() {
  const { t } = useTranslation();
  const dataBlog = useDataBlog(); // Chame o hook diretamente

  const [posts, setPosts] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [contentLimit, setContentLimit] = useState(200);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Atualiza o limite de caracteres com base no tamanho da janela
  useEffect(() => {
    const updateContentLimit = () => {
      const limit = window.innerWidth < 1024 ? 100 : 200;
      setContentLimit(limit);
    };

    window.addEventListener("resize", updateContentLimit);
    updateContentLimit();

    return () => window.removeEventListener("resize", updateContentLimit);
  }, []); // Sem dependências adicionais

  // Aplica a tradução nos dados do blog
  useEffect(() => {
    try {
      const translatedPosts = dataBlog.map((post) => ({
        ...post,
        title: t(post.titleKey), // Atualizado para titleKey
        content: Array.isArray(post.contentKeys) // Atualizado para contentKeys
          ? post.contentKeys.map((c) => t(c))
          : [t(post.contentKey)], // Atualizado para contentKey
        sections: post.sections.map((section) => ({
          ...section,
          title: t(section.titleKey), // Atualizado para titleKey
          subtitle: section.subtitleKey ? t(section.subtitleKey) : "",
          contents: section.contentKeys.map((c) => t(c)), // Atualizado para contentKeys
        })),
      }));

      // Ordena os posts pela data de publicação (mais recentes primeiro)
      translatedPosts.sort(
        (a, b) => new Date(b.publishDate) - new Date(a.publishDate)
      );

      setPosts(translatedPosts);
      setIsLoading(false);
    } catch (err) {
      setError(t("ErrorLoadingPosts")); // Mensagem de erro traduzida
      setIsLoading(false);
    }
  }, [dataBlog, t]); // Adicione dataBlog e t como dependências

  // Função para lidar com a mudança de filtro
  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  // Filtra os posts com base no filtro selecionado
  const filteredPosts = selectedFilter
    ? posts.filter((post) => post.postType === selectedFilter)
    : posts;

  // Função para formatar a data
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString("es-es", options);
  };

  return (
    <div>
      <Header Titulo={t("Blog")} backgroundImage={header} />
      <div className={styles.content}>
        {isLoading ? (
          <div>{t("loadingPosts")}</div>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <>
            <FloatButton mobileScrollPoint={500} desktopScrollPoint={300} />
            <div className={styles.contentTitulo}>
              <h1 className={styles.title}>{t("Home10")}</h1>
              <h2 className={styles.subtitle}>{t("Home11")}</h2>
              <h3 className={styles.description}>{t("Home12")}</h3>
            </div>
            <PostFilter
              selectedFilter={selectedFilter}
              onFilterChange={handleFilterChange}
            />
            <div className={styles.contentBlog}>
              {filteredPosts.length > 0 ? (
                filteredPosts.map((post) => (
                  <Link
                    to={`/post/${post._id}`}
                    key={post._id}
                    className={styles.linkPost}
                  >
                    <div className={styles.cardBlog}>
                      {post.photo && (
                        <img
                          src={post.photo}
                          alt={post.title}
                          className={`${styles.imgBlog} ${
                            post._id === "3" ? styles.specialPostBlog : ""
                          }
                       ${post._id === "4" ? styles.PostBlogGota : ""}`}
                        />
                      )}
                      <div className={styles.cardContent}>
                        <h2 className={styles.titleBlog}>{post.title}</h2>
                        <PostContent
                          content={post.content}
                          limit={contentLimit}
                          className={styles.postContent}
                        />
                        <p className={styles.dateBlog}>
                          {formatDate(post.publishDate)}
                        </p>
                      </div>
                    </div>
                  </Link>
                ))
              ) : (
                <p className={styles.msgNoPost}>{t("Blog04")}</p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Blog;
