import Header from "../components/layout/Header";
import imgAgenda from "../assets/images_home/HomeAgendar2.webp";
import { useState, useEffect } from "react";

//Imagem icones
import iconDuracao from "../assets/aplicacion.svg";
import Icon from "../components/layout/Icones";
import iconRecuperacion from "../assets/icons/tiemporecuperacion.png";
import iconDuracion from "../assets/icons/duracion.svg";
import iconAnestesia from "../assets/icons/anestesia.png";

import { IoMdArrowForward } from "react-icons/io";
import { useInView } from "react-intersection-observer";

import header from "../assets/RellenoLabios/RellenoLabiosHeader.webp";
import imgInicio from "../assets/RellenoLabios/RellenoLabiosText.webp";
import imgLiftingCard from "../assets/RellenoLabios/RellenoLabiosCard1.webp";

import styles from "./lifting.module.css";
import { AiOutlineMedicineBox } from "react-icons/ai";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import Perguntas from "../components/Perguntas";
import { Button } from "react-bootstrap";
import CardDuvidas from "../components/layout/cardsDuvidas";
import FloatButton from "../components/FloatButton";
//Translation
import { useTranslation } from "react-i18next";

//Components

function RellenodeLabios() {
  const { ref, inView } = useInView({
    triggerOnce: true, // A animação será acionada apenas uma vez
    threshold: 0.1, // A animação será acionada quando 50% do elemento estiver visível
  });

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (inView) {
      setAnimate(true);
    }
  }, [inView]);

  //Translation
  const { t } = useTranslation();

  return (
    <div>
      <Header
        Titulo={t("Labios01")}
        backgroundImage={header}
        id={styles.headerRelleno}
        backgroundPosition="30% 50%"
      />
      <FloatButton mobileScrollPoint={500} desktopScrollPoint={300} />
      <div className={styles.containerIcons}>
        <div className={styles.circleBig}></div>
        <div className={styles.iconsTexto}>
          <Icon
            src={iconDuracao}
            title={t("Labios02")}
            subtitle={t("Labios03")}
          />
          <Icon
            src={iconAnestesia}
            title={t("Labios04")}
            subtitle={t("Labios05")}
          />
        </div>
        <div className={styles.iconsTexto}>
          <Icon
            src={iconRecuperacion}
            title={t("Labios06")}
            subtitle={t("Labios07")}
          />
          <Icon
            src={iconDuracion}
            title={t("Labios08")}
            subtitle={t("Labios09")}
          />
        </div>

        <div className={styles.linhaVertical}></div>
        <div className={styles.icon}>
          <a href="https://wa.me/34651551473" target="_blank">
            <Button className={styles.button}> {t("pedircitaLifting")} </Button>
          </a>
        </div>

        <div className={styles.halfCircle}></div>
        <div className={styles.circle}></div>
      </div>

      <div className={styles.containerIntro}>
        <div>
          <img
            src={imgInicio}
            className={`${styles.imgIntro} ${
              animate ? `${styles.fadeIn} ${styles.slideRight}` : ""
            }`}
            ref={ref}
            alt="background header"
          />
        </div>
        <div className={styles.containerTexto}>
          <h2
            className={`${styles.titleIntro} ${
              animate ? `${styles.fadeIn} ${styles.slideLeft}` : ""
            }`}
            ref={ref}
          >
            {t("Labios10")}
            <p className={styles.subtitleIntro}>
              {" "}
              {t("Labios11")} con el Dr. Alex Moreira
            </p>
          </h2>
          <p>{t("Labios12")}</p>
        </div>
      </div>
      <ParallaxProvider>
        <Parallax speed={30} translateY={["0px", "320px"]}>
          <h1 className={styles.titlePrincipalContainer}> {t("Labios13")} </h1>
          <div className={styles.circleTitle}></div>
        </Parallax>
      </ParallaxProvider>

      <div className={styles.containerPrincipal}>
        <div className={styles.contentPrincipal}>
          <div className={styles.principal}>
            <CardDuvidas
              title={t("Labios14")}
              content={[t("Labios15")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />
            <CardDuvidas
              title={t("Labios16")}
              content={[t("Labios17")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />
            <CardDuvidas
              title={t("Labios18")}
              content={[t("Labios18")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />
          </div>
        </div>
      </div>
      <div className={styles.containerProcesso}>
        <div>
          <div className={` ${styles.slideRight}`} id={styles.cardTratamiento}>
            <div className={styles.contentTratamiento}>
              <p className={styles.titlePrincipal}> {t("Labios20")} </p>
              <p className={styles.conteudoTratamiento}>
                <span key="1">
                  <strong> {t("Labios21")} </strong> {t("Labios22")}
                </span>
                <br />
                <span key="2">
                  <strong> {t("Labios23")} </strong> {t("Labios24")}
                </span>
                <br />

                <span key="3">
                  <strong> {t("Labios25")} </strong> {t("Labios26")}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.descricao}>
          <h4 className={styles.slideLeft}> {t("Labios27")} </h4>
          <div>
            <ul className={styles.listTratamiento}>
              <li>
                <div className={styles.containerList}>
                  {/* <div className={styles.linhaVerticalIcons}></div> */}
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>

                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}>{t("Labios28")}</p>
                    <p>{t("Labios29")}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className={styles.containerList}>
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>
                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}> {t("Labios30")} </p>
                    <p>{t("Labios31")}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className={styles.containerList}>
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>
                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}>{t("Labios32")}</p>
                    <p>{t("Labios33")}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.containerPlus}>
        <h4> {t("Labios34")} </h4>
        <div className={styles.contentPlus}>
          <div className={styles.circlePlus}>
            <a href="/PerfiladoMandibular"> {t("Labios35")} </a>
          </div>
          <div className={styles.circlePlus}>
            <a href="RellenodeMenton"> {t("Labios36")} </a>
          </div>
          <div className={styles.circlePlus}>
            <a href="Rinomodelacion"> {t("Labios37")} </a>
          </div>
        </div>
        <div>
          <Button className={styles.button} id={styles.buttonPlus}>
            <a className={styles.linkServicios} href="/Servicios">
              {t("linkTratamientos")}
            </a>
          </Button>
        </div>
      </div>
      <div className={styles.containerAgenda}>
        <div className={styles.cardAgenda}>
          <div className={styles.contentAgenda}>
            <h1 className={styles.titleAgenda}> {t("agendaLifting")} </h1>
            <h1 className={styles.subtitleAgenda}>
              {" "}
              {t("tuCitaAhoraLifting")}{" "}
            </h1>
          </div>
          <a href="https://wa.me/669762160">
            <Button className={styles.buttonAgenda}>
              {" "}
              {t("pedircitaLifting")}{" "}
            </Button>
          </a>
        </div>
        <img src={imgAgenda} className={styles.imgAgenda} />
      </div>
      <div className={styles.containerPerguntas}>
        <div className={styles.perguntas}>
          <h2> {t("Labios38")} </h2>
          <Perguntas
            pergunta={t("Labios39")}
            resposta={t("Labios40")}
            eventKey="0"
            onClick="0"
          />
          <Perguntas
            pergunta={t("Labios41")}
            resposta={t("Labios42")}
            eventKey="1"
          />
          <Perguntas
            pergunta={t("Labios43")}
            resposta={t("Labios44")}
            eventKey="2"
          />
          <Perguntas
            pergunta={t("Labios45")}
            resposta={t("Labios46")}
            eventKey="3"
          />
        </div>
      </div>
    </div>
  );
}

export default RellenodeLabios;
