import styles from "./Footer.module.css";

//Icones
import { FaInstagram, FaFacebook, FaTiktok, FaWhatsapp } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";
import { FaClock } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import SobreMiIcon from "../../assets/IconeSobreMi01.webp";
import MiFilosofiaIcon from "../../assets/IconeFilosofia01.webp";
import { TfiPencilAlt } from "react-icons/tfi";

//Translation
import { useTranslation } from "react-i18next";

import Logo from "../../assets/LogotipoVerde.gif";

function Rodape() {
  //Translation
  const { t } = useTranslation();

  return (
    <div className={styles.footer}>
      <div className={styles.sectionFooter} id={styles.sbFooter}>
        <div className={styles.sectionLink}>
          <div className={styles.footerLink}>
            <a href="/">
              <img src={Logo} className={styles.logo} id={styles.colunaLogo} />
            </a>
            <p>{t("Footer01")}</p>
            <a href="/sobremi" className={styles.iconTextContainer}>
              <div>
                <img src={SobreMiIcon} className={styles.iconInfo} />
                <p> {t("Footer02")} </p>
              </div>
            </a>
            <a href="/sobremi" className={styles.iconTextContainer}>
              <div>
                <img src={MiFilosofiaIcon} className={styles.iconInfo} />
                <p> {t("Footer03")} </p>
              </div>
            </a>
            <a href="/blog">
              <div>
                <TfiPencilAlt className={styles.iconInfo} />
                <p> {t("Footer04")} </p>
              </div>
            </a>
          </div>
          <div className={styles.footerLink} id={styles.colunaTratamientos}>
            <a>
              <h4> {t("Footer05")} </h4>
            </a>
            <a href="/servicios">
              <p> {t("Footer06")} </p>
            </a>
            <a href="/servicios">
              <p> {t("Footer07")} </p>
            </a>
            <a href="/servicios">
              <p> {t("Footer08")} </p>
            </a>

            <div className={styles.socialMedia}>
              <a href="https://www.facebook.com/dralexmoreira" target="_blank">
                <FaFacebook className={styles.media} />
              </a>
              <a
                href="https://www.instagram.com/dr.alex_moreira/"
                target="_blank"
              >
                <FaInstagram className={styles.media} />
              </a>
              <a href="https://wa.me/34651551473" target="_blank">
                <FaWhatsapp className={styles.media} />
              </a>
            </div>
          </div>

          <div className={styles.footerLink} id={styles.colunaBuscados}>
            <a>
              <h4> {t("Footer09")} </h4>
            </a>
            <a href="/Laserlipolisis">
              <p> {t("Footer10")} </p>
            </a>
            <a href="/HIFUCorporal">
              <p> {t("Footer11")} </p>
            </a>
            <a href="/LaserCO2">
              <p> {t("Footer12")} </p>
            </a>
            <a href="/Rinomodelacion">
              <p> {t("Footer13")} </p>
            </a>
            <a href="/PerfiladoMandibular">
              <p>{t("Footer14")} </p>
            </a>
          </div>
          <div className={styles.footerLink}>
            <h4>{t("Footer15")} </h4>
            <div className={styles.iconLink}>
              <a href="tel:+34651551473">
                <BsFillTelephoneFill />

                <p>+34 651 55 14 73</p>
              </a>
            </div>
            <div className={styles.iconLink}>
              <a href="mailto:info@dralexmoreiraesthetic.com">
                <MdEmail />
                <p id={styles.linkEmail}>info@dralexmoreiraesthetic.com</p>
              </a>
            </div>

            <div className={styles.iconLink}>
              <a
                href="https://maps.app.goo.gl/FwiEJmCM7EAjybHn7"
                target="_blank"
              >
                <FaLocationDot className={styles.iconLocation} />

                <p> {t("Footer18")} </p>
              </a>
            </div>

            <div className={styles.iconLink}>
              <a>
                <FaClock />

                <p> {t("Footer20")} </p>
              </a>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className={styles.footerBelow}>
          <div className={styles.footerCopyright}>
            <p>
              @{new Date().getFullYear()} {t("Footer21")}
            </p>
          </div>
          <div className={styles.bellowLinks}>
            <a>
              <div>
                <p> {t("Footer22")} </p>
              </div>
            </a>
            <a>
              <div>
                <p> {t("Footer23")} </p>
              </div>
            </a>
            <a>
              <div>
                <p> {t("Footer24")} </p>
              </div>
            </a>
            <a>
              <div>
                <p> {t("Footer25")} </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Rodape;
