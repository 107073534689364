import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import "./Navbar.css";
import btnMenuGreen from "../../assets/btn-menu-verde.svg";
import { FaInstagram, FaFacebook, FaTiktok, FaWhatsapp } from "react-icons/fa";
import close from "../../assets/Close-menu.png";
import btnMenu from "../../assets/btn-menu.svg";
import logoBlack from "../../assets/Logotipo.webp";
import logoWhite from "../../assets/Logotipo-Moreira-blanc.webp";
import Button from "react-bootstrap/Button";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

import { FaArrowLeft } from "react-icons/fa";

import "bootstrap/dist/css/bootstrap.min.css";

//Translation
import { useTranslation } from "react-i18next";

//Buttons
import ButtonGreen from "../layout/ButtonGreen";
import ButtonBlack from "../layout/ButtonBlack";
import DropdownLang from "../dropdown/dropdown-lang";

function Navbar() {
  const closeBtnRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);
  const [isWhiteBg, setIsWhiteBg] = useState(false);
  const location = useLocation();
  const [open, setOpen] = useState(false);

  //Translation
  const { t } = useTranslation();

  const [submenuVisible, setSubmenuVisible] = useState({
    facial: false,
    corporal: false,
    laserCO2: false,
  });

  const [clicked, setClicked] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    // Função para ajustar o estado baseado no tamanho da tela
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (isMobile) {
      // Fecha todos os submenus se for móvel
      setSubmenuVisible({
        facial: false,
        corporal: false,
        laserCO2: false,
      });
    }
  }, [isMobile]);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(!open);
    const body = document.body;
    if (!open) {
      body.classList.add("no-scroll");
    } else {
      body.classList.remove("no-scroll");
    }
  };

  const toggleSubmenu = (submenuName) => {
    setActiveSubmenu(activeSubmenu === submenuName ? null : submenuName);
  };

  useEffect(() => {
    // Função para verificar se a barra de rolagem passou de um certo ponto
    const handleScroll = () => {
      const offset = window.scrollY;
      const shouldBeScrolled = offset > 800;
      if (shouldBeScrolled !== scrolled) {
        setScrolled(shouldBeScrolled);
      }
    };

    // Função para ajustar o estado baseado na mudança de rota
    const handleLocationChange = () => {
      setIsWhiteBg(location.pathname !== "/");
    };

    // Chamada inicial para os estados com base na localização atual ao carregar
    handleLocationChange();

    // Adicionando o evento de scroll
    window.addEventListener("scroll", handleScroll, { passive: true });

    // Chamada de retorno que remove o ouvinte de eventos quando o componente é desmontado ou quando location muda
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location, scrolled]); // Ensure effect runs on location change

  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const handleSubmenuToggle = (submenuName) => {
    if (activeSubmenu === submenuName) {
      setActiveSubmenu(null); // Se clicado novamente no mesmo submenu, ele fecha
    } else {
      setActiveSubmenu(submenuName); // Abre o submenu clicado e fecha os outros
    }
  };

  const handleLinkClick = (event, submenuName) => {
    event.preventDefault(); // Impede a navegação padrão do link
    if (activeSubmenu === submenuName) {
      setActiveSubmenu(null); // Fecha o submenu se ele já estiver aberto
    } else {
      setActiveSubmenu(submenuName); // Abre o submenu clicado e fecha os outros
    }
  };
  const [logo, setLogo] = useState(logoWhite); // Estado inicial com a logo branca

  useEffect(() => {
    const img1 = new Image();
    img1.src = logoBlack;
    const img2 = new Image();
    img2.src = logoWhite;
    // Pré-carrega as imagens
  }, []);

  useEffect(() => {
    // Atualiza o estado da logo com base em condições específicas
    setLogo(scrolled || isWhiteBg ? logoBlack : logoWhite);
  }, [scrolled, isWhiteBg]);

  return (
    <nav
      className={`navbar fixed-top ${scrolled ? "scrolled" : ""} ${
        isWhiteBg ? "white-bg" : ""
      }`}
    >
      <div className="container-fluid">
        <a className="navbar-brand" href="/" id="navHome">
          <img
            src={scrolled || isWhiteBg ? logoBlack : logoWhite}
            alt="Logo"
            className="logotipo"
          />
        </a>
        <div className="menu-lang">
          <button
            className={`navbar-button ${scrolled ? "scrolled" : ""}`}
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span>
              <img
                src={scrolled || isWhiteBg ? btnMenuGreen : btnMenu}
                className="btnMenu"
                alt="Menu"
              />
              <p className="textoMenu"> {t("Menu29")} </p>
            </span>
          </button>
          <DropdownLang />
        </div>
        <div className={`social-medias ${scrolled ? "scrolled" : ""}`}>
          <a
            href="https://www.instagram.com/dr.alex_moreira/"
            rel="noopener noreferrer"
            className="item"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.facebook.com/dralexmoreira"
            target="_blank"
            className="item"
          >
            <FaFacebook />
          </a>
          <a href="https://wa.me/34651551473" target="_blank" className="item">
            <FaWhatsapp />
          </a>
          <div className="containerButton">
            <a href="https://wa.me/34651551473" target="_blank">
              <Button className={`button-cita ${scrolled ? "scrolled" : ""}`}>
                {t("Menu30")}
              </Button>{" "}
            </a>
          </div>
        </div>

        <div
          className="offcanvas offcanvas-start offcanvas-custom"
          tabIndex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div className="vertical-offcanvas">
            <span
              className="close-btn"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              ref={closeBtnRef}
            >
              <img src={close} alt="Close" />
            </span>
          </div>
          <div>
            <ul className="menu">
              <li className="menu">
                <a href="/"> {t("Menu01")} </a>
              </li>
              <li className="menu">
                <a href="/sobremi"> {t("Menu02")} </a>
              </li>
              <li className="menu">
                <a
                  href="\servicios"
                  onClick={(e) => handleLinkClick(e, "facial")}
                >
                  {t("Menu03")} <IoIosArrowDown className="arrow-icon" />
                </a>
                <ul className="submenu-1">
                  <li className="list-1">
                    <a href="#" id="facial">
                      {t("Menu04")} <IoIosArrowForward />
                    </a>
                    <ul className="submenu-2">
                      <li className="list-2">
                        <a>
                          {t("Menu05")} <IoIosArrowForward />
                        </a>
                        <ul className="submenu-3">
                          <li className="list-3">
                            <a href="/arrugasdeexpresion">{t("Menu06")}</a>
                          </li>
                          <li className="list-3">
                            <a href="/liftingSinCirugia">{t("Menu07")}</a>
                          </li>

                          <li className="list-3">
                            <a href="/surconasogeniano"> {t("Menu08")} </a>
                          </li>
                          <li className="list-3">
                            <a href="/ojeras"> {t("Menu09")} </a>
                          </li>
                        </ul>
                      </li>
                      <li className="list-2">
                        <a>
                          {t("Menu10")} <IoIosArrowForward />{" "}
                        </a>
                        <ul className="submenu-3">
                          <li className="list-3">
                            <a href="/perfiladomandibular">{t("Menu11")}</a>
                          </li>
                          <li className="list-3">
                            <a href="/rellenodelabios"> {t("Menu12")} </a>
                          </li>
                          <li className="list-3">
                            <a href="/rellenodementon"> {t("Menu13")} </a>
                          </li>
                          <li className="list-3">
                            <a href="/rinomodelacion"> {t("Menu14")} </a>
                          </li>
                        </ul>
                      </li>
                      <li className="list-2">
                        <a>
                          {t("Menu15")} <IoIosArrowForward />
                        </a>
                        <ul className="submenu-3">
                          <li className="list-3">
                            <a href="/peeling"> {t("Menu16")} </a>
                          </li>
                          <li className="list-3">
                            <a href="/mesoterapia"> {t("Menu17")} </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li className="list-1">
                    <a>
                      {t("Menu18")} <IoIosArrowForward />
                    </a>
                    <ul className="submenu-2">
                      <li className="list-2">
                        <a>
                          {t("Menu19")} <IoIosArrowForward />
                        </a>
                        <ul className="submenu-3">
                          <li className="list-3">
                            <a href="/laserlipolisis"> {t("Menu20")} </a>
                          </li>
                          <li className="list-3">
                            <a href="/HIFUCorporal"> {t("Menu21")} </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li className="list-1">
                    <a>
                      <a> {t("Menu22")} </a>
                      <IoIosArrowForward />
                    </a>
                    <ul className="submenu-2 ">
                      <li className="list-2">
                        <a href="/laserco2"> {t("Menu23")} </a>
                      </li>
                      <li className="list-2">
                        <a href="/laserco2"> {t("Menu24")} </a>
                      </li>
                      <li className="list-2">
                        <a href="/laserco2"> {t("Menu25")}</a>
                      </li>
                      <li className="list-2">
                        <a href="/laserco2"> {t("Menu26")} </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="menu">
                <a href="/contacto"> {t("Menu27")} </a>
              </li>
              <li className="menu">
                <a href="/blog"> {t("Menu28")} </a>
              </li>
              <li className="menu">
                <a href="/servicios" className="button-servicios">
                  <ButtonBlack content={t("linkTratamientos")} />
                </a>
              </li>
              <li className="menu">
                <a
                  href="https://wa.me/34651551473"
                  className="button-servicios"
                  id="buttonCita"
                  target="_blank"
                >
                  <ButtonGreen content={t("Menu30")} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
