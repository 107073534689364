import "./Formulario.css";
import Form from "react-bootstrap/Form";
import { Modal, Button } from "react-bootstrap";
import ButtonGreen from "./layout/ButtonGreen";
import { useState } from "react";

// Tradução
import { useTranslation } from "react-i18next";

function Formulario() {
  const [validated, setValidated] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // Substituir o EmailJS por Formspree
  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (!form.checkValidity()) {
      event.stopPropagation();
    } else {
      const formData = new FormData(form); // Coleta os dados do formulário

      try {
        // Enviar os dados para o Formspree
        const response = await fetch("https://formspree.io/f/manwrwlg", {
          // Substitua pelo seu endpoint do Formspree
          method: "POST",
          body: formData,
          headers: {
            Accept: "application/json",
          },
        });

        if (response.ok) {
          setShowModal(true); // Mostra o modal de sucesso
          setValidated(false);
          form.reset(); // Limpa o formulário após o envio
        } else {
          alert("Erro ao enviar o formulário.");
        }
      } catch (error) {
        alert("Erro ao enviar o formulário: " + error.message);
      }
    }
    setValidated(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setValidated(false); // Reseta a validação ao fechar o modal
  };

  // Tradução
  const { t } = useTranslation();

  return (
    <div className="containerForm">
      <h2 className="titleForm"> {t("Contacto32")} </h2>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        id="contactForm"
      >
        <Form.Control
          required
          type="text"
          placeholder={t("Contacto33")}
          className="formControl"
          id="name"
          name="Nombre" // Adicione o nome dos campos para o Formspree
        />
        <Form.Control
          required
          type="email"
          placeholder={t("Contacto34")}
          className="formControl"
          id="email"
          name="Correo Electrónico" // Adicione o nome dos campos para o Formspree
        />
        <Form.Control
          required
          type="text"
          placeholder={t("Contacto35")}
          className="formControl"
          id="phone"
          name="Teléfono" // Adicione o nome dos campos para o Formspree
        />
        <Form.Control
          as="textarea"
          rows={3}
          className="formControl"
          id="textArea"
          name="Mensaje" // Adicione o nome dos campos para o Formspree
          placeholder={t("Contacto36")}
        />

        <div className="buttonFormulario">
          <ButtonGreen type="submit" content={t("Contacto37")} />
        </div>
      </Form>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("Contacto38")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("Contacto39")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Formulario;
