// components/AdminAuth.js
import React, { useState } from "react";
import styles from "./AdminAuth.module.css";
import ButtonGreen from "../../../components/layout/ButtonGreen";
function AdminAuth({ onAuthSuccess }) {
  const [pin, setPin] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://api.dralexmoreiraesthetic.com/api/admin",
        {
          method: "GET",
          headers: {
            pin: pin,
          },
        }
      );

      if (response.ok) {
        onAuthSuccess();
      } else {
        setError("PIN inválido");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Erro ao autenticar");
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.titleAuth}>Autenticação de Admin</h2>
      <form onSubmit={handleSubmit} className={styles.formAuth}>
        <input
          type="text"
          value={pin}
          onChange={(e) => setPin(e.target.value)}
          placeholder="Digite o PIN"
        />
        <ButtonGreen
          type="submit"
          title="submit"
          content="Entrar"
          className={styles.buttonAuth}
        ></ButtonGreen>
        {error && <p>{error}</p>}
      </form>
    </div>
  );
}

export default AdminAuth;
