import React, { useEffect, useState } from "react";
import styles from "./ServiciosStyles.module.css";

import { useInView } from "react-intersection-observer";
//Component
import Header from "../components/layout/Header";
import ButtonBlack from "../components/layout/ButtonBlack";
import FloatButton from "../components/FloatButton";

//Images
import imgFacial from "../assets/Servicios/ServicioFacial.webp";
import imgCorporal from "../assets/Servicios/ServicioCorporal.webp";
import header from "../assets/Servicios/ServiciosHeader.png";
import smallHeader from "../assets/Blog/imgPost3.webp";
import imgLaser from "../assets/Servicios/ServiciosLaser.webp";

import { useTrail, a } from "@react-spring/web";
import Acordeao from "../components/layout/Acordeao";

//Translation
import { useTranslation } from "react-i18next";

function Servicios() {
  const { ref, inView } = useInView({
    triggerOnce: false, // A animação será acionada apenas uma vez
    threshold: 0.1, // A animação será acionada quando 50% do elemento estiver visível
  });

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (inView) {
      setAnimate(true);
    }
  }, [inView]);

  //Translation
  const { t } = useTranslation();

  const Trail = ({ open, children }) => {
    const items = React.Children.toArray(children);
    const trail = useTrail(items.length, {
      config: { mass: 5, tension: 2000, friction: 200 },
      opacity: open ? 2 : 0,
      x: open ? 0 : 40,
      height: open ? 110 : 0,
      from: { opacity: 0, x: 20, height: 0 },
    });

    return (
      <div>
        {trail.map(({ height, ...style }, index) => (
          <a.div key={index} className={styles.trailsText} style={style}>
            <a.div style={{ height }}>{items[index]}</a.div>
          </a.div>
        ))}
      </div>
    );
  };

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const h1Element = document.querySelector(`.${styles.containerAgenda} h1`);
      if (h1Element) {
        const topPosition = h1Element.getBoundingClientRect().top;
        const bottomPosition = h1Element.getBoundingClientRect().bottom;
        const windowHeight = window.innerHeight;
        const distanceFromBottom = windowHeight - bottomPosition; // Corrigindo o cálculo da distância

        if (distanceFromBottom >= 50) {
          setOpen(true);
        } else {
          setOpen(false);
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [headerImage, setHeaderImage] = useState(header); // Estado para a imagem do header

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 450) {
        setHeaderImage(smallHeader); // Mudar a imagem se a tela for menor que 450px
      } else {
        setHeaderImage(header); // Usar a imagem normal para telas maiores
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Chama na montagem do componente para ajustar o header inicialmente

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const h1Element = document.querySelector(`.${styles.containerAgenda} h1`);
      if (h1Element) {
        const topPosition = h1Element.getBoundingClientRect().top;
        const bottomPosition = h1Element.getBoundingClientRect().bottom;
        const windowHeight = window.innerHeight;
        const distanceFromBottom = windowHeight - bottomPosition; // Corrigindo o cálculo da distância

        if (distanceFromBottom >= 50) {
          setOpen(true);
        } else {
          setOpen(false);
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={styles.containerServicios}>
      <Header
        Titulo={t("Servicio01")}
        backgroundImage={headerImage}
        id={styles.headerServicios}
        backgroundPosition={"center"}
      />
      <FloatButton mobileScrollPoint={500} desktopScrollPoint={300} />
      <div className={styles.servicios}>
        <div className={styles.containerServicio}>
          <img src={imgFacial} alt="Tratamento Facial" />
          <div className={styles.containerContent}>
            <h1 className={styles.titleE}>
              {t("Servicio02")}{" "}
              <p className={styles.subtitleIntro}> {t("Servicio03")} </p>
            </h1>
            <p className={styles.contentE}>{t("Servicio04")}</p>
            <p className={styles.contentE}>{t("Servicio05")} </p>
            <p className={styles.contentE}>{t("Servicio06")}</p>
          </div>
        </div>
        <div className={styles.containerAcordeao}>
          <div>
            <Acordeao title={t("Servicio07")} content={t("Servicio08")} />
            <div className={styles.containerLinks}>
              <ul>
                <li>
                  <a className={styles.linksAcordeao}>
                    <a href="/LíneasdeExpresion"> {t("Servicio09")} </a>
                  </a>
                </li>
                <li>
                  <a className={styles.linksAcordeao}>
                    <a href="/Lifting"> {t("Servicio10")} </a>
                  </a>
                </li>
                <li>
                  <a className={styles.linksAcordeao}>
                    <a href="/SurcoNasogeniano"> {t("Servicio11")} </a>
                  </a>
                </li>
                <li>
                  <a className={styles.linksAcordeao}>
                    <a href="/Ojeras"> {t("Servicio12")} </a>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <Acordeao title={t("Servicio13")} content={t("Servicio14")} />
            <div className={styles.containerLinks}>
              <ul>
                <li>
                  <a className={styles.linksAcordeao}>
                    <a href="/perfiladoMandibular"> {t("Servicio15")} </a>
                  </a>
                </li>
                <li>
                  <a className={styles.linksAcordeao}>
                    <a href="/RellenodeLabios"> {t("Servicio16")} </a>
                  </a>
                </li>
                <li>
                  <a className={styles.linksAcordeao}>
                    <a href="/RellenodeMenton"> {t("Servicio17")} </a>
                  </a>
                </li>
                <li>
                  <a className={styles.linksAcordeao}>
                    <a href="/Rinomodelacion"> {t("Servicio18")} </a>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <Acordeao title={t("Servicio19")} content={t("Servicio20")} />
            <div className={styles.containerLinks}>
              <ul>
                <li>
                  <a className={styles.linksAcordeao}>
                    <a href="/Peeling"> {t("Servicio21")} </a>
                  </a>
                </li>
                <li>
                  <a className={styles.linksAcordeao}>
                    <a href="/Mesoterapia"> {t("Servicio22")} </a>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.containerServicioD}>
          <div>
            <h1 className={styles.titleD}>{t("Servicio23")}</h1>
            <p className={styles.subtitleIntro}> {t("Servicio24")} </p>
            <p className={styles.contentD}>{t("Servicio25")}</p>

            <div className={styles.containerLinksD}>
              <ul>
                <li>
                  <a className={styles.linksAcordeaoD}>
                    <a href="/Laserlipolisis"> {t("Servicio26")} </a>
                  </a>
                </li>
                <li>
                  <a className={styles.linksAcordeaoD}>
                    <a href="/HIFUCorporal"> {t("Servicio27")} </a>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <img src={imgCorporal} alt="Tratamiento Facial" />
        </div>
        <div className={styles.containerServicio}>
          <img src={imgLaser} alt="Tratamento Facial" />
          <div className={styles.containerContent}>
            <h1 className={styles.titleE}>
              {t("Servicio28")}{" "}
              <p className={styles.subtitleIntro}> {t("Servicio29")} </p>
            </h1>
            <p className={styles.contentE}>{t("Servicio30")}</p>
            <div id={styles.links}>
              <ul>
                <li>
                  <a className={styles.linksAcordeao}>
                    <a href="/Laserco2"> {t("Servicio31")} </a>
                  </a>
                </li>
                <li>
                  <a className={styles.linksAcordeao}>
                    <a href="/Laserco2"> {t("Servicio32")} </a>
                  </a>
                </li>
                <li>
                  <a className={styles.linksAcordeao}>
                    <a href="/Laserco2"> {t("Servicio33")} </a>
                  </a>
                </li>
                <li>
                  <a className={styles.linksAcordeaoD}>
                    <a href="/Laserco2"> {t("Servicio34")} </a>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.containerAcordeao}></div>{" "}
        <div className={styles.containerAgenda}>
          <Trail open={open}>
            <div>
              <h1> {t("Servicio35")} </h1>
              <h1> {t("Servicio36")} </h1>
            </div>
          </Trail>
          <div>
            <Trail open={open}>
              <a href="https://wa.me/34651551473" target="_blank">
                <ButtonBlack content={t("pedircitaLifting")} />
              </a>
            </Trail>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Servicios;
