import Header from "../components/layout/Header";
import imgAgenda from "../assets/images_home/HomeAgendar2.webp";
import { useState, useEffect } from "react";

//Imagem icones
import iconDuracao from "../assets/aplicacion.svg";
import Icon from "../components/layout/Icones";
import iconRecuperacion from "../assets/icons/tiemporecuperacion.png";
import iconDuracion from "../assets/icons/duracion.svg";
import iconAnestesia from "../assets/icons/anestesia.png";

import { IoMdArrowForward } from "react-icons/io";
import { useInView } from "react-intersection-observer";

import header from "../assets/Mesoterapia/MesoterapiaHeader1.webp";
import imgInicio from "../assets/Mesoterapia/MesoterapiaText.webp";
import imgLiftingCard from "../assets/Mesoterapia/MesoterapiaHeader.webp";

import styles from "./lifting.module.css";
import { AiOutlineMedicineBox } from "react-icons/ai";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import Perguntas from "../components/Perguntas";
import { Button } from "react-bootstrap";
import CardDuvidas from "../components/layout/cardsDuvidas";
import FloatButton from "../components/FloatButton";
//Translation
import { useTranslation } from "react-i18next";

//Components

function Mesoterapia() {
  const { ref, inView } = useInView({
    triggerOnce: true, // A animação será acionada apenas uma vez
    threshold: 0.5, // A animação será acionada quando 50% do elemento estiver visível
  });

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (inView) {
      setAnimate(true);
    }
  }, [inView]);

  //Translation
  const { t } = useTranslation();

  return (
    <div>
      <Header
        Titulo={t("Mesoterapia01")}
        backgroundImage={header}
        id="headerLifting"
      />
      <FloatButton mobileScrollPoint={500} desktopScrollPoint={300} />
      <div className={styles.containerIcons}>
        <div className={styles.circleBig}></div>
        <div className={styles.iconsTexto}>
          <Icon
            src={iconDuracao}
            title={t("Mesoterapia02")}
            subtitle={t("Mesoterapia03")}
          />
          <Icon
            src={iconAnestesia}
            title={t("Mesoterapia04")}
            subtitle={t("Mesoterapia05")}
          />
        </div>
        <div className={styles.iconsTexto}>
          <Icon
            src={iconRecuperacion}
            title={t("Mesoterapia06")}
            subtitle={t("Mesoterapia07")}
          />
          <Icon
            src={iconDuracion}
            title={t("Mesoterapia08")}
            subtitle={t("Mesoterapia09")}
          />
        </div>

        <div className={styles.linhaVertical}></div>
        <div className={styles.icon}>
          <a href="https://wa.me/34651551473" target="_blank">
            <Button className={styles.button}> {t("pedircitaLifting")} </Button>
          </a>
        </div>

        <div className={styles.halfCircle}></div>
        <div className={styles.circle}></div>
      </div>

      <div className={styles.containerIntro}>
        <div>
          <img
            src={imgInicio}
            className={`${styles.imgIntro} ${
              animate ? `${styles.fadeIn} ${styles.slideRight}` : ""
            }`}
            ref={ref}
            alt="background header"
          />
        </div>
        <div className={styles.containerTexto}>
          <h2
            className={`${styles.titleIntro} ${
              animate ? `${styles.fadeIn} ${styles.slideLeft}` : ""
            }`}
            ref={ref}
          >
            {t("Mesoterapia10")}
            <p className={styles.subtitleIntro}>{t("Mesoterapia11")} </p>
          </h2>
          <p>{t("Mesoterapia12")}</p>
        </div>
      </div>
      <ParallaxProvider>
        <Parallax speed={30} translateY={["0px", "320px"]}>
          <h1 className={styles.titlePrincipalContainer}>
            {" "}
            {t("Mesoterapia13")}
          </h1>
          <div className={styles.circleTitle}></div>
        </Parallax>
      </ParallaxProvider>

      <div className={styles.containerPrincipal}>
        <div className={styles.contentPrincipal}>
          <div className={styles.principal}>
            <CardDuvidas
              title={t("Mesoterapia14")}
              content={[t("Mesoterapia15")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
              isLarge={true}
            />
            <CardDuvidas
              title={t("Mesoterapia16")}
              content={[t("Mesoterapia17")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />
            <CardDuvidas
              title={t("Mesoterapia18")}
              content={[t("Mesoterapia19")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />
          </div>
        </div>
      </div>
      <div className={styles.containerProcesso}>
        <div>
          <div className={`${styles.slideRight} `} id={styles.cardTratamiento}>
            <div className={styles.contentTratamiento}>
              <p className={styles.titlePrincipal}>
                {" "}
                {t("beneficioMesoterapia")}{" "}
              </p>
              <p className={styles.conteudoTratamiento}>
                <span key="1" className={styles.contentBeneficio}>
                  <strong className={styles.contentBeneficio}>
                    {t("Mesoterapia40")}{" "}
                  </strong>{" "}
                  {t("Mesoterapia41")}
                </span>
                <br />
                <span key="2" className={styles.contentBeneficio}>
                  <strong className={styles.contentBeneficio}>
                    {t("Mesoterapia42")}
                  </strong>{" "}
                  {t("Mesoterapia43")}
                </span>
                <br />
                <span key="3" className={styles.contentBeneficio}>
                  <strong className={styles.contentBeneficio}>
                    {t("Mesoterapia44")}
                  </strong>{" "}
                  {t("Mesoterapia45")}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.descricao}>
          <h4 className={styles.slideLeft}> {t("Mesoterapia20")} </h4>
          <div>
            <ul className={styles.listTratamiento}>
              <li>
                <div className={styles.containerList}>
                  {/* <div className={styles.linhaVerticalIcons}></div> */}
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>

                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}>
                      {t("Mesoterapia21")}
                    </p>
                    <p>{t("Mesoterapia22")}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className={styles.containerList}>
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>
                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}>
                      {" "}
                      {t("Mesoterapia23")}{" "}
                    </p>
                    <p>{t("Mesoterapia24")}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className={styles.containerList}>
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>
                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}>
                      {t("Mesoterapia25")}
                    </p>
                    <p>{t("Mesoterapia26")}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.containerPlus}>
        <h4> {t("Mesoterapia27")} </h4>
        <p className={styles.subtitlePlus}>{t("Mesoterapia28")}</p>
        <div className={styles.contentPlus} id={styles.plusAlternativo}>
          <div className={styles.circlePlus}>
            <a href="/Peeling"> {t("Mesoterapia29")} </a>
          </div>
          <Button className={styles.button} id={styles.buttonPlus}>
            <a className={styles.linkServicios} href="/Servicios">
              {t("linkTratamientos")}
            </a>
          </Button>
        </div>
      </div>
      <div className={styles.containerAgenda}>
        <div className={styles.cardAgenda}>
          <div className={styles.contentAgenda}>
            <h1 className={styles.titleAgenda}> {t("agendaLifting")} </h1>
            <h1 className={styles.subtitleAgenda}>
              {" "}
              {t("tuCitaAhoraLifting")}{" "}
            </h1>
          </div>
          <a href="https://wa.me/669762160">
            <Button className={styles.buttonAgenda}>
              {" "}
              {t("pedircitaLifting")}{" "}
            </Button>
          </a>
        </div>
        <img src={imgAgenda} className={styles.imgAgenda} />
      </div>
      <div className={styles.containerPerguntas}>
        <div className={styles.perguntas}>
          <h2> {t("Mesoterapia30")} </h2>
          <Perguntas
            pergunta={t("Mesoterapia31")}
            resposta={t("Mesoterapia32")}
            eventKey="0"
            onClick="0"
          />
          <Perguntas
            pergunta={t("Mesoterapia33")}
            resposta={t("Mesoterapia34")}
            eventKey="1"
          />
          <Perguntas
            pergunta={t("Mesoterapia35")}
            resposta={t("Mesoterapia36")}
            eventKey="2"
          />
          <Perguntas
            pergunta={t("Mesoterapia37")}
            resposta={t("Mesoterapia38")}
            eventKey="3"
          />
        </div>
      </div>
    </div>
  );
}

export default Mesoterapia;
