import evento from "../assets/Blog/Alex-Evento.jpg";
import imgPost3 from "../assets/Blog/imgPost3.webp";
import imgPost1 from "../assets/Blog/imgPost1.webp";
import imgPost4 from "./image_carrossel_gota.png";
import imgPost5 from "../assets/Blog/imgPost5.jpg";
import imgPost6 from "../assets/Blog/imagPost6.jpeg";
import imgPost7Section from "../assets/Blog/máquina.jfif";
import imgPost7 from "../assets/Components/PopUp/backgroundSemBlur.jpg";
import imgPost8 from "../assets/Blog/post8.webp";
import imgPost9 from "../assets/Blog/imgPost9.jpg";

import { useTranslation } from "react-i18next";

const useDataBlog = () => {
  const { t } = useTranslation();

  return [
    {
      _id: "1",
      titleKey: t("blogTituloPost1"), // Chave para tradução
      contentKeys: [t("post1Titulo1")], // Chaves para tradução
      postType: "procedimentos",
      photo: imgPost8,
      publishDate: "2024-08-14",
      relatedPostsIds: ["2", "3"],
      sections: [
        {
          titleKey: t("post1Titulo1"), // Chave para tradução
          subtitleKey: "", // Se não houver subtítulo, deixe vazio ou remova
          contentKeys: [
            t("post1conteudo1"),
            t("post1conteudo2"),
            t("post1conteudo3"),
            t("post1conteudo4"),
            t("post1conteudo5"),
            t("post1conteudo6"),
            t("post1conteudo7"),
          ], // Chaves para tradução
        },
        {
          titleKey: t("post1Titulo2"), // Chave para tradução
          subtitleKey: "",
          contentKeys: [
            t("post1conteudo8"),
            t("post1list1"),
            t("post1list2"),
            t("post1list3"),
            t("post1list4"),
          ], // Chaves para tradução
        },
        {
          titleKey: t("post1Titulo3"), // Chave para tradução
          subtitleKey: t("post1conteudo8"), // Chave para tradução
          contentKeys: [t("post1list5"), t("post1list6"), t("post1list7")], // Chaves para tradução
        },
        {
          titleKey: "",
          subtitleKey: t("post1conteudo10"), // Chave para tradução
          contentKeys: [t("post1conteudo11"), t("post1list8"), t("post1list9")], // Chaves para tradução
        },
        {
          titleKey: t("post1conteudo14"), // Chave para tradução
          subtitleKey: "",
          contentKeys: [t("post1conteudo15")], // Chaves para tradução
        },
      ],
    },
    {
      _id: "2",
      titleKey: t("post2Title1"), // Chave para tradução
      contentKeys: [t("post2subtitle1")], // Chaves para tradução
      postType: "noticias",
      photo: evento,
      publishDate: "2024-08-17",
      relatedPostsIds: ["1", "3"],
      sections: [
        {
          titleKey: t("post2subtitle1"), // Chave para tradução
          contentKeys: [t("post2conteudo1")], // Chaves para tradução
        },
        {
          subtitleKey: t("post2subtitle2"), // Chave para tradução
          contentKeys: [t("post2conteudo2")], // Chaves para tradução
        },
        {
          subtitleKey: t("post2subtitle3"), // Chave para tradução
          contentKeys: [t("post2conteudo3")], // Chaves para tradução
        },
        {
          subtitleKey: t("post2subtitle4"), // Chave para tradução
          contentKeys: [t("post2conteudo4"), t("post2conteudo5")], // Chaves para tradução
        },
      ],
    },
    {
      _id: "3",
      titleKey: t("post3Title1"), // Chave para tradução
      contentKeys: [t("post3subtitle1")], // Chaves para tradução
      postType: "consejos",
      photo: imgPost3,
      publishDate: "2024-09-02",
      relatedPostsIds: ["1", "2"],
      sections: [
        {
          titleKey: t("post3section1"), // Chave para tradução
          contentKeys: [t("post3conteudo1")], // Chaves para tradução
        },
        {
          subtitleKey: t("post3section2"), // Chave para tradução
          contentKeys: [t("post3conteudo2")], // Chaves para tradução
        },
        {
          subtitleKey: t("post3section3"), // Chave para tradução
          contentKeys: [t("post3conteudo3")], // Chaves para tradução
        },
        {
          subtitleKey: t("post3section4"), // Chave para tradução
          contentKeys: [t("post3conteudo4")], // Chaves para tradução
        },
        {
          subtitleKey: t("post3section5"), // Chave para tradução
          contentKeys: [t("post3conteudo5")], // Chaves para tradução
        },
        {
          subtitleKey: t("post3section6"), // Chave para tradução
          contentKeys: [t("post3conteudo6"), t("post3conteudo7")], // Chaves para tradução
        },
      ],
    },
    {
      _id: "4",
      titleKey: t("post4Title1"), // Chave para tradução
      contentKeys: [t("post4subtitle1")], // Chaves para tradução
      postType: "consejos",
      photo: imgPost4,
      publishDate: "2024-09-02",
      relatedPostsIds: ["1", "2", "3"],
      sections: [
        {
          titleKey: t("post4section1"), // Chave para tradução
          contentKeys: [t("post4conteudo1")], // Chaves para tradução
        },
        {
          titleKey: t("post4section2"), // Chave para tradução
          contentKeys: [t("post4conteudo2")], // Chaves para tradução
        },
        {
          titleKey: t("post4section3"),
          subtitleKey: t("post4conteudo3"), // Chave para tradução
          contentKeys: [
            t("post4conteudo4"),
            t("post4conteudo5"),
            t("post4conteudo6"),
            t("post4conteudo7"),
            t("post4conteudo8"),
          ], // Chaves para tradução
        },
        {
          titleKey: t("post4section4"),
          subtitleKey: t("post4conteudo9"),
          contentKeys: [
            t("post4conteudo10"),
            t("post4conteudo11"),
            t("post4conteudo12"),
            t("post4conteudo13"),
          ], // Chaves para tradução
        },
        {
          titleKey: t("post4section5"), // Chave para tradução
          contentKeys: [
            t("post4conteudo14"),
            t("post4conteudo15"),
            t("post4conteudo16"),
            t("post4conteudo17"),
            t("post4conteudo18"),
            t("post4conteudo19"),
            t("post4conteudo20"),
          ],
        },
        {
          titleKey: t("post4section6"), // Chave para tradução
          contentKeys: [t("post3conteudo21")], // Chaves para tradução
        },
        {
          contentKeys: [""],
          subtitleKey: t("post4conteudo22"),
        },
      ],
    },
    {
      _id: "5",
      titleKey: t("post5title"), // Chave para tradução
      contentKeys: [t("post5subtitle")], // Chaves para tradução
      postType: "praticas",
      photo: imgPost5,
      publishDate: "2024-09-28",
      relatedPostsIds: ["1", "2", "3"],
      sections: [
        {
          titleKey: t("post5section1"), // Chave para tradução
          contentKeys: [t("post5conteudo1")], // Chaves para tradução
        },
        {
          titleKey: t("post5section2"),
          subtitleKey: t("post5subtitle1"), // Chave para tradução
          contentKeys: [t("post5conteudo2")], // Chaves para tradução
        },
        {
          subtitleKey: t("post5subtitle2"), // Chave para tradução
          contentKeys: [t("post5conteudo3")], // Chaves para tradução
        },
        {
          subtitleKey: t("post5subtitle3"),
          contentKeys: [t("post5conteudo4")], // Chaves para tradução
        },
        {
          subtitleKey: t("post5subtitle4"),
          contentKeys: [t("post5conteudo5")], // Chaves para tradução
        },
        {
          titleKey: t("post5title2"),
          subtitleKey: t("post5subtitle5"), // Chave para tradução
          contentKeys: [t("post5conteudo6")], // Chaves para tradução
        },
        {
          titleKey: t("post5title3"),
          contentKeys: [t("post5conteudo70")], // Chaves para tradução
        },
      ],
    },
    {
      _id: "6",
      titleKey: t("post6Title"), // Chave para tradução
      contentKeys: [t("post6subtitle")], // Chaves para tradução
      postType: "tendencias",
      photo: imgPost6,
      publishDate: "2024-09-28",
      relatedPostsIds: ["1", "2", "3"],
      sections: [
        {
          titleKey: t("post6section1"), // Chave para tradução
          contentKeys: [t("post6conteudo1")], // Chaves para tradução
        },
        {
          titleKey: t("post6section2"),
          subtitleKey: t("post6subtitle1"), // Chave para tradução
          contentKeys: [t("post6conteudo2")], // Chaves para tradução
        },
        {
          titleKey: t("post6section2"), // Adicionado
          contentKeys: [t("post6conteudo5")], // Chaves para tradução
        },
        {
          subtitleKey: t("post6subtitle2"), // Adicionado
          contentKeys: [t("post6conteudo6"), t("post6conteudo7")], // Chaves para tradução
        },
        {
          titleKey: t("post6section3"),
          contentKeys: [t("post6conteudo8")],
        },
        {
          subtitleKey: t("post6subtitle3"), // Adicionado
          contentKeys: [t("post6conteudo9"), t("post6conteudo10")], // Chaves para tradução
        },
        {
          titleKey: t("post6section4"), // Chave para tradução
          contentKeys: [t("post6conteudo11")], // Chaves para tradução
        },
        {
          subtitleKey: t("post6subtitle4"), // Adicionado
          contentKeys: [t("post6conteudo12"), t("post6conteudo13")], // Chaves para tradução
        },
        {
          titleKey: t("post6section5"), // Chave para tradução
          contentKeys: [t("post6conteudo14")], // Chaves para tradução
        },
        {
          subtitleKey: t("post6subtitle5"), // Adicionado
          contentKeys: [t("post6conteudo15"), t("post6conteudo16")], // Chaves para tradução
        },
        {
          titleKey: t("post6section6"), // Chave para tradução
          contentKeys: [t("post6conteudo17")], // Chaves para tradução
        },
        {
          subtitleKey: t("post6subtitle6"), // Adicionado
          contentKeys: [t("post6conteudo18"), t("post6conteudo19")], // Chaves para tradução
        },
        {
          contentKeys: [t("post6conteudo20"), t("post6conteudo21")],
        },
      ],
    },
    {
      _id: "7",
      titleKey: t("post7Title"), // Chave para tradução
      contentKeys: [t("post7subtitle")], // Chaves para tradução
      postType: "procedimentos",
      photo: imgPost7,
      publishDate: "2024-10-28",
      relatedPostsIds: ["1", "2", "3"],
      sections: [
        {
          contentKeys: [t("post7conteudo1")], // Chaves para tradução
        },
        {
          titleKey: t("post7section1"),
          contentKeys: [t("post7conteudo2")], // Chaves para tradução
        },
        {
          titleKey: t("post7section2"),
          subtitleKey: t("post7subtitle1"), // Chave para tradução
          contentKeys: [
            t("post7conteudo3"),
            t("post7conteudo4"),
            t("post7conteudo5"),
            t("post7conteudo6"),
          ], // Chaves para tradução
        },
        {
          titleKey: t("post7section3"),
          subtitleKey: t("post7subtitle2"), // Chave para tradução
          contentKeys: [
            t("post7conteudo7"),
            t("post7conteudo8"),
            t("post7conteudo9"),
            t("post7conteudo10"),
          ], // Chaves para tradução
        },
        {
          titleKey: t("post7section4"), // Chave para tradução
          contentKeys: [t("post7conteudo11")], // Chaves para tradução
        },
        {
          titleKey: t("post7section5"),
          contentKeys: [t("post7conteudo12")],
        },
        {
          titleKey: t("post7section6"),
          subtitleKey: t("post7subtitle3"),
          contentKeys: [
            t("post7conteudo13"),
            t("post7conteudo14"),
            t("post7conteudo15"),
            t("post7conteudo16"),
            t("post7conteudo17"),
            t("post7conteudo18"),
          ], // Chaves para tradução
        },
        {
          titleKey: t("post7section7"), // Chave para tradução
          contentKeys: [t("post7conteudo19"), t("post7conteudo20")], // Chaves para tradução
        },
        {
          titleKey: t("post7section8"),
          contentKeys: [t("post7conteudo21")],
          image: imgPost7Section,
        },
        {
          titleKey: t("post7section9"),
          subtitleKey: t("post7subtitle4"),
          contentKeys: [
            t("post7conteudo22"),
            t("post7conteudo23"),
            t("post7conteudo24"),
          ],
        },
        {
          titleKey: t("post7section10"),
          subtitleKey: t("post7subtitle5"),
          contentKeys: [
            t("post7conteudo25"),
            t("post7conteudo26"),
            t("post7conteudo27"),
          ],
        },
        {
          titleKey: t("post7section11"),

          contentKeys: [t("post7conteudo28"), t("post7conteudo29")],
        },
      ],
    },
    {
      _id: "8",
      titleKey: t("post8Title"), // Chave para tradução
      contentKeys: [t("post8subtitle")], // Chaves para tradução
      postType: "procedimentos",
      photo: imgPost1,
      publishDate: "2024-11-03",
      relatedPostsIds: ["1", "2", "3"],
      sections: [
        {
          contentKeys: [t("post8conteudo1")], // Chaves para tradução
        },
        {
          titleKey: t("post8section1"),
          contentKeys: [t("post8conteudo2")], // Chaves para tradução
        },
        {
          titleKey: t("post8section2"),

          contentKeys: [t("post8conteudo3")], // Chaves para tradução
        },
        {
          titleKey: t("post8section3"),
          subtitleKey: t("post8subtitle1"), // Chave para tradução
          contentKeys: [
            t("post8conteudo4"),
            t("post8conteudo5"),
            t("post8conteudo6"),
          ], // Chaves para tradução
        },
        {
          titleKey: t("post8section4"),
          subtitleKey: t("post8subtitle2"), // Chave para tradução
          contentKeys: [
            t("post8conteudo7"),
            t("post8conteudo8"),
            t("post8conteudo9"),
            t("post8conteudo10"),
          ], //  // Chaves para tradução
        },
        {
          titleKey: t("post8section5"),
          contentKeys: [t("post8conteudo11")],
        },
        {
          titleKey: t("post8section6"),

          contentKeys: [t("post8conteudo12"), t("post8conteudo13")], // Chaves para tradução
        },
      ],
    },
    {
      _id: "9",
      titleKey: t("post9Title"), // Chave para tradução
      contentKeys: [t("post9subtitle")], // Chaves para tradução
      postType: "procedimentos",
      photo: imgPost9,
      publishDate: "2024-11-12",
      relatedPostsIds: ["1", "2", "3"],
      sections: [
        {
          contentKeys: [t("post9conteudo1")], // Chaves para tradução
        },
        {
          titleKey: t("post9section1"),
          contentKeys: [t("post9conteudo2")], // Chaves para tradução
        },
        {
          titleKey: t("post9section2"),
          subtitleKey: t("post9subtitle1"),
          contentKeys: [t("post9conteudo3")], // Chaves para tradução
        },
        {
          
          subtitleKey: t("post9subtitle2"),
          contentKeys: [t("post9conteudo4")], // Chaves para tradução
        },
        {
          subtitleKey: t("post9subtitle3"),
          contentKeys: [t("post9conteudo5")], // Chaves para tradução
        },
        {
          subtitleKey: t("post9subtitle4"),
          contentKeys: [t("post9conteudo6")], // Chaves para tradução
        },
        {
          subtitleKey: t("post9subtitle5"),
          contentKeys: [t("post9conteudo7")], // Chaves para tradução
        },
        {
          titleKey: t("post9section3"),
          subtitleKey: t("post9subtitle6"), // Chave para tradução
          contentKeys: [
            t("post9conteudo8"),
            t("post9conteudo9"),
            t("post9conteudo10"),
          ], // Chaves para tradução
        },
       
        {
          titleKey: t("post9section4"),

          contentKeys: [t("post9conteudo11")], // Chaves para tradução
        },
      ],
    },
  ];
};

export default useDataBlog;
