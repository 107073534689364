import React from "react";
import { useState } from "react";

import styles from "./Sobre.module.css";
import Header from "../components/layout/Header.js";

//Images Perfil
import imgPerfil from "../assets/images_sobre/imgPerfil1.jpg";

import header from "../assets/images_sobre/SobreHeader2.webp";

import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import FloatButton from "../components/FloatButton.js";
//Translation
import { useTranslation } from "react-i18next";

function SobreMi() {
  const [showMore, setShowMore] = useState(false);

  const { ref, inView } = useInView({
    triggerOnce: true, // A animação será acionada apenas uma vez
    threshold: 0.5, // A animação será acionada quando 50% do elemento estiver visível
  });

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (inView) {
      setAnimate(true);
    }
  }, [inView]);

  //Translation
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Header
        Titulo={t("SobreMi01")}
        backgroundImage={header}
        id={styles.headerAdd}
      />
      <FloatButton mobileScrollPoint={500} desktopScrollPoint={300} />
      <div className={styles.containerSobre} id={styles.containerSobreAdd}>
        <div className={styles.imgContainer}>
          <img
            src={imgPerfil}
            alt="Imagem de Perfil"
            className={`${styles.imgPerfil} ${
              animate ? ` ${styles.slideRight}` : ""
            }`}
            ref={ref}
          />
        </div>
        <div className={styles.containerTexto}>
          <h1
            className={`${styles.titleSobre} ${
              animate ? ` ${styles.slideLeft}` : ""
            }`}
            ref={ref}
          >
            {t("SobreMi02")}
          </h1>
          <p>{t("SobreMi03")}</p>
          <p>{t("SobreMi04")}</p>
          <p>{t("SobreMi05")}</p>
          <p>{t("SobreMi06")}</p>
        </div>
      </div>
      <div className={styles.containerSobre}>
        <div className={styles.containerTexto2}>
          <h3 className={styles.slideRight}>{t("SobreMi07")}</h3>
          <p>{t("SobreMi08")}</p>

          <p>{t("SobreMi09")}</p>
          <p>{t("SobreMi10")}</p>
        </div>
      </div>
      <div className={styles.containerFrase}>
        <h1> {t("SobreMi11")} </h1>
        <div className={styles.circle}></div>
        <div className={styles.circleSmall}></div>
      </div>
      <div className={styles.imagemAtendimento}>
        <div className={styles.filosofia}>
          <h1> {t("SobreMi12")} </h1>
          <p>{t("SobreMi13")}</p>
          <p>{t("SobreMi14")}</p>
          <div
            className={`${styles.moreContent} ${showMore ? styles.open : ""}`}
          >
            {showMore && (
              <>
                <p>{t("SobreMi15")}</p>
                <p>{t("SobreMi16")}</p>
                <p>{t("SobreMi17")}</p>
              </>
            )}
          </div>
          <button
            onClick={() => setShowMore(!showMore)}
            className={styles.buttonFilosofia}
          >
            {showMore ? t("verMenosFilosofia") : t("verMaisFilosofia")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default SobreMi;
