import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {} from "bootstrap";
import ReactDOM from "react-dom";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

//Pages
import Home from "./pages/Home/Home";
import Contacto from "./pages/Contacto";
import SobreMi from "./pages/SobreMi";
import Lifting from "./pages/Lifting";
import ArrugasdeExpresion from "./pages/ArrugasdeExpresion";
import HIFUCorporal from "./pages/HIFUCorporal";

import SurcoNasogeniano from "./pages/SurcoNasogeniano";

import PerfiladoMandibular from "./pages/PerfiladoMandibular";
import RellenodeLabios from "./pages/RellenodeLabios";
import RellenodeMenton from "./pages/RellenodeMenton";
import Rinomodelacion from "./pages/Rinomodelacion";
import Peeling from "./pages/Peeling";
import Mesoterapia from "./pages/Mesoterapia";
import Laserlipolisis from "./pages/Laserlipolisis";

import LaserCO2 from "./pages/LaserCO2";
import Ojeras from "./pages/Ojeras";

import PostBlog from "./pages/Blog/Components/Blog/PostBlog";

//Components
import Navbar from "./components/Menu/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import Rodape from "./components/layout/Footer";
import Servicios from "./pages/Servicios";

//Blog
import Blog from "./pages/Blog/Blog";
import Admin from "./pages/Blog/AdminBlog";

//Translation
import "./locales/i18.mjs";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);

  const handleLogin = (authToken) => {
    setIsAuthenticated(true);
    setToken(authToken);
  };

  return (
    <div>
      <Router>
        <Navbar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="/sobremi" element={<SobreMi />} />
          <Route path="/servicios" element={<Servicios />} />
          <Route path="/blog" element={<Blog />} />

          <Route path="/liftingSinCirugia" element={<Lifting />} />
          <Route path="/ArrugasdeExpresion" element={<ArrugasdeExpresion />} />
          <Route path="/surconasogeniano" element={<SurcoNasogeniano />} />
          <Route
            path="/PerfiladoMandibular"
            element={<PerfiladoMandibular />}
          />
          <Route path="/RellenodeLabios" element={<RellenodeLabios />} />
          <Route path="/RellenodeMenton" element={<RellenodeMenton />} />
          <Route path="/Rinomodelacion" element={<Rinomodelacion />} />
          <Route path="/Peeling" element={<Peeling />} />
          <Route path="/Mesoterapia" element={<Mesoterapia />} />
          <Route path="/Laserlipolisis" element={<Laserlipolisis />} />

          <Route
            path="/PerfiladoMandibular"
            element={<PerfiladoMandibular />}
          />
          <Route path="/RellenodeLabios" element={<RellenodeLabios />} />
          <Route path="/RellenodeMenton" element={<RellenodeMenton />} />
          <Route path="/Rinomodelacion" element={<Rinomodelacion />} />
          <Route path="/Peeling" element={<Peeling />} />
          <Route path="/Mesoterapia" element={<Mesoterapia />} />
          <Route path="/Laserlipolisis" element={<Laserlipolisis />} />

          <Route path="/lifting" element={<Lifting />} />
          <Route path="/arrugasdeExpresion" element={<ArrugasdeExpresion />} />
          <Route path="/surconasogeniano" element={<SurcoNasogeniano />} />
          <Route
            path="/perfiladomandibular"
            element={<PerfiladoMandibular />}
          />
          <Route path="/rellenodelabios" element={<RellenodeLabios />} />
          <Route path="/rellenodementon" element={<RellenodeMenton />} />
          <Route path="/rinomodelacion" element={<Rinomodelacion />} />
          <Route path="/peeling" element={<Peeling />} />
          <Route path="/mesoterapia" element={<Mesoterapia />} />
          <Route path="/laserlipolisis" element={<Laserlipolisis />} />

          <Route path="/laserco2" element={<LaserCO2 />} />
          <Route path="/ojeras" element={<Ojeras />} />
          <Route path="/HIFUCorporal" element={<HIFUCorporal />} />

          <Route path="/admin" element={<Admin />} />
          <Route path="/post/:postId" element={<PostBlog />} />
        </Routes>
        <Rodape />
      </Router>
    </div>
  );
}

export default App;
ReactDOM.render(<App />, document.getElementById("root"));
