import { useMediaQuery } from "react-responsive";
import styles from "./Header.module.css";
import React from "react";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";

function Header({
  Titulo,
  subtitulo,
  backgroundImage,
  backgroundPosition,
  isMobileOverride = false,
}) {
  // Detecta se a tela é menor que 1000 pixels
  const isMobileAuto = useMediaQuery({ maxWidth: 1200 });

  // Determina o modo móvel pela prop isMobileOverride se fornecida, senão pelo valor automático
  const isMobile =
    isMobileOverride !== undefined ? isMobileOverride : isMobileAuto;

  return (
    <div className={styles.header}>
      <img
        src={backgroundImage}
        alt="Header Background"
        className={styles.backgroundImage}
        style={{ objectPosition: isMobile ? "center" : backgroundPosition }}
      />
      <div className={styles.overlay}>
        <ParallaxProvider>
          <Parallax
            speed={20}
            translateX={isMobile ? ["0px", "0px"] : ["-10px", "10px"]}
          >
            <div>
              <h1>{Titulo}</h1>
              <p>{subtitulo}</p>
            </div>
          </Parallax>
        </ParallaxProvider>
      </div>
    </div>
  );
}

export default Header;
