import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "./translations/index.mjs";

export const langs = [
  {
    value: "en",
    label: "En",
    icon: "https://flagcdn.com/60x45/us.png",
  },
  {
    value: "es",
    label: "Es",
    icon: "https://flagcdn.com/60x45/es.png",
  },
  {
    value: "fr",
    label: "Fr",
    icon: "https://flagcdn.com/60x45/fr.png",
  },
];

i18n.use(initReactI18next).init({
  resources,
  lng: "es",
  fallbackLng: "es",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
