import React from "react";
import PropTypes from "prop-types";

function truncateHtml(content, limit) {
  const div = document.createElement("div");
  div.innerHTML = content;
  let truncatedContent = "";

  const nodes = div.childNodes;
  for (let i = 0; i < nodes.length; i++) {
    if (truncatedContent.length >= limit) break;
    const node = nodes[i];
    if (node.nodeType === Node.TEXT_NODE) {
      truncatedContent += node.nodeValue.slice(
        0,
        limit - truncatedContent.length
      );
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      const innerHtml = node.outerHTML.slice(
        0,
        limit - truncatedContent.length
      );
      truncatedContent += innerHtml;
    }
  }

  if (truncatedContent.length < content.length) {
    truncatedContent += "...";
  }

  return truncatedContent;
}

function PostContent({ content, limit, className }) {
  const truncatedContent = truncateHtml(content, limit);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: truncatedContent }}
      className={className}
    />
  );
}

PostContent.propTypes = {
  content: PropTypes.string.isRequired,
  limit: PropTypes.number,
  className: PropTypes.string,
};

PostContent.defaultProps = {
  limit: 100, // Ajuste o limite de caracteres conforme necessário
};

export default PostContent;
