import React, { useState, useEffect } from "react";
import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";
import "./FloatButton.css";
import { FaWhatsapp } from "react-icons/fa";

function FloatButton({
  mobileScrollPoint,
  tabletScrollPoint,
  desktopScrollPoint,
}) {
  const [isVisible, setIsVisible] = useState(false);

  // Função para determinar qual ponto de rolagem usar com base na largura da tela
  const getScrollPoint = () => {
    if (window.innerWidth <= 768) return mobileScrollPoint;
    if (window.innerWidth <= 1024) return tabletScrollPoint;
    return desktopScrollPoint;
  };

  const toggleVisibility = () => {
    const scrollPoint = getScrollPoint();

    if (window.pageYOffset > scrollPoint) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <Box
      sx={{ "& > :not(style)": { m: 1 } }}
      className={isVisible ? "visible" : "hidden"}
    >
      <a
        href="https://wa.me/34651551473"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Fab color="primary" aria-label="add" className="button">
          <FaWhatsapp className="whatsapp" />
        </Fab>
      </a>
    </Box>
  );
}

export default FloatButton;
