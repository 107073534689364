import Header from "../components/layout/Header";
import imgAgenda from "../assets/images_home/HomeAgendar2.webp";
import { useState, useEffect } from "react";

//Imagem icones
import iconDuracao from "../assets/aplicacion.svg";
import Icon from "../components/layout/Icones";
import iconRecuperacion from "../assets/icons/tiemporecuperacion.png";
import iconDuracion from "../assets/icons/duracion.svg";
import iconAnestesia from "../assets/icons/anestesia.png";

import { IoMdArrowForward } from "react-icons/io";
import { useInView } from "react-intersection-observer";

import header from "../assets/PerfiladoMandibular/PerfiladoHeaderCort1.jpg";
import imgInicio from "../assets/PerfiladoMandibular/PerfiladoMandibularCortText.webp";
import imgLiftingCard from "../assets/PerfiladoMandibular/PerfiladoMandibularText.webp";

import styles from "./lifting.module.css";
import { AiOutlineMedicineBox } from "react-icons/ai";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import Perguntas from "../components/Perguntas";
import { Button } from "react-bootstrap";
import CardDuvidas from "../components/layout/cardsDuvidas";
import FloatButton from "../components/FloatButton";
//Translation
import { useTranslation } from "react-i18next";

//Components

function PerfiladoMandibular() {
  const { ref, inView } = useInView({
    triggerOnce: true, // A animação será acionada apenas uma vez
    threshold: 0.5, // A animação será acionada quando 50% do elemento estiver visível
  });

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (inView) {
      setAnimate(true);
    }
  }, [inView]);

  //Translation
  const { t } = useTranslation();

  return (
    <div>
      <Header
        Titulo={t("Perfilato01")}
        backgroundImage={header}
        id="headerLifting"
      />
      <FloatButton mobileScrollPoint={500} desktopScrollPoint={300} />
      <div className={styles.containerIcons}>
        <div className={styles.circleBig}></div>
        <div className={styles.iconsTexto}>
          <Icon
            src={iconDuracao}
            title={t("Perfilato02")}
            subtitle={t("Perfilato03")}
          />
          <Icon
            src={iconAnestesia}
            title={t("Perfilato04")}
            subtitle={t("Perfilato05")}
          />
        </div>
        <div className={styles.iconsTexto}>
          <Icon
            src={iconRecuperacion}
            title={t("Perfilato06")}
            subtitle={t("Perfilato07")}
          />
          <Icon
            src={iconDuracion}
            title={t("Perfilato08")}
            subtitle={t("Perfilato09")}
          />
        </div>

        <div className={styles.linhaVertical}></div>
        <div className={styles.icon}>
          <a href="https://wa.me/34651551473" target="_blank">
            <Button className={styles.button}> {t("pedircitaLifting")} </Button>
          </a>
        </div>

        <div className={styles.halfCircle}></div>
        <div className={styles.circle}></div>
      </div>

      <div className={styles.containerIntro}>
        <div>
          <img
            src={imgInicio}
            className={`${styles.imgIntro} ${
              animate ? `${styles.fadeIn} ${styles.slideRight}` : ""
            }`}
            ref={ref}
            alt="background header"
          />
        </div>
        <div className={styles.containerTexto}>
          <h2
            className={`${styles.titleIntro} ${
              animate ? `${styles.fadeIn} ${styles.slideLeft}` : ""
            }`}
            ref={ref}
          >
            {t("Perfilato10")}
            <p className={styles.subtitleIntro}> {t("Perfilato11")} </p>
          </h2>
          <p>{t("Perfilato12")}</p>
        </div>
      </div>
      <ParallaxProvider>
        <Parallax speed={30} translateY={["0px", "320px"]}>
          <h1 className={styles.titlePrincipalContainer}>{t("Perfilato13")}</h1>
          <div className={styles.circleTitle}></div>
        </Parallax>
      </ParallaxProvider>

      <div className={styles.containerPrincipal}>
        <div className={styles.contentPrincipal}>
          <div className={styles.principal}>
            <CardDuvidas
              title={t("Perfilato14")}
              content={[t("Perfilato15")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />
            <CardDuvidas
              title={t("Perfilato16")}
              content={[t("Perfilato17")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />
            <CardDuvidas
              title={t("Perfilato18")}
              content={[t("Perfilato19")]}
              className={styles.cardPrincipal}
              backgroundImage={imgLiftingCard}
            />
          </div>
        </div>
      </div>
      <div className={styles.containerProcesso}>
        <div>
          <div className={`${styles.slideRight}`} id={styles.cardTratamiento}>
            <div className={styles.contentTratamiento}>
              <p className={styles.titlePrincipal}> {t("Labios20")} </p>
              <p className={styles.conteudoTratamiento}>
                <span key="1">
                  <strong> {t("Perfilato21")} </strong> {t("Perfilato22")}
                </span>
                <br />
                <span key="2">
                  <strong> {t("Perfilato23")} </strong> {t("Perfilato24")}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.descricao}>
          <h4 className={styles.slideLeft}> {t("Perfilato25")} </h4>
          <div>
            <ul className={styles.listTratamiento}>
              <li>
                <div className={styles.containerList}>
                  {/* <div className={styles.linhaVerticalIcons}></div> */}
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>

                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}>{t("Perfilato26")}</p>
                    <p>{t("Perfilato27")}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className={styles.containerList}>
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>
                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}> {t("Perfilato28")}</p>
                    <p>{t("Perfilato29")}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className={styles.containerList}>
                  <div className={styles.iconList}>
                    <span>
                      <AiOutlineMedicineBox />
                    </span>
                  </div>
                  <div className={styles.contentList}>
                    <p className={styles.titleDescricao}>{t("Perfilato30")}</p>
                    <p>{t("Perfilato31")}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.containerPlus}>
        <h4> {t("Perfilato32")} </h4>
        <div className={styles.contentPlus}>
          <div className={styles.circlePlus}>
            <a href="/RellenodeLabios"> {t("Perfilato33")} </a>
          </div>
          <div className={styles.circlePlus}>
            <a href="/RellenodeMenton">{t("Perfilato34")} </a>
          </div>
          <div className={styles.circlePlus}>
            <a href="/Rinomodelacion"> {t("Perfilato35")} </a>
          </div>
        </div>
        <div>
          <Button className={styles.button} id={styles.buttonPlus}>
            <a className={styles.linkServicios} href="/Servicios">
              {t("linkTratamientos")}
            </a>
          </Button>
        </div>
      </div>
      <div className={styles.containerAgenda}>
        <div className={styles.cardAgenda}>
          <div className={styles.contentAgenda}>
            <h1 className={styles.titleAgenda}> {t("agendaLifting")} </h1>
            <h1 className={styles.subtitleAgenda}>
              {" "}
              {t("tuCitaAhoraLifting")}{" "}
            </h1>
          </div>
          <a href="https://wa.me/669762160">
            <Button className={styles.buttonAgenda}>
              {" "}
              {t("pedircitaLifting")}{" "}
            </Button>
          </a>
        </div>
        <img src={imgAgenda} className={styles.imgAgenda} />
      </div>
      <div className={styles.containerPerguntas}>
        <div className={styles.perguntas}>
          <h2> {t("Perfilato36")} </h2>
          <Perguntas
            pergunta={t("Perfilato37")}
            resposta={t("Perfilato38")}
            eventKey="0"
            onClick="0"
          />
          <Perguntas
            pergunta={t("Perfilato39")}
            resposta={t("Perfilato40")}
            eventKey="1"
          />
          <Perguntas
            pergunta={t("Perfilato41")}
            resposta={t("Perfilato42")}
            eventKey="2"
          />
          <Perguntas
            pergunta={t("Perfilato43")}
            resposta={t("Perfilato44")}
            eventKey="3"
          />
          <Perguntas
            pergunta={t("Perfilato45")}
            resposta={t("Perfilato46")}
            eventKey="4"
          />
        </div>
      </div>
    </div>
  );
}

export default PerfiladoMandibular;
